import React, { useState, useEffect } from 'react'

import { useStyles } from '..'
import styles from './jss/badge'
import clsx from 'clsx'



const Badge = ({ color, value, className }) => {
    const interpolation = { color }

    const classes = useStyles(styles, interpolation)
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => setAnimate(true), 500)
        return () => clearTimeout(timeout)
    }, [])

    const badgeStyle = clsx({
        [classes.root]: true,
        [classes.animate]: animate,
    })


    return (
        <div className={`${badgeStyle} ${className}`}>
            <div className={classes.value}>{value}</div>
        </div>
    )
}

Badge.defaultProps = {
    className : ''
}

export default Badge