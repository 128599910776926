export default theme => ({
    root: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        zIndex:'99999',
        maxHeight: '85vh'
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },

    grow: {
        flexGrow: 1
    },

    title: {
        fontSize: 16,
        color: theme.palette.default.text,
        padding: 5,
        fontWeight: 600
    },

    close: {
        padding: 5,
        height: 15,
        width: 15,
        borderRadius: '50%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        '&:active': {
            backgroundColor: 'rgba(0,0,0,.2)'
        }
    },

    closeIcon: {
        color: theme.palette.default.text
    },
})