import React, { useState } from 'react'

import { useStyles } from '../../../styles'
import styles from './jss/nestedChild'
import { FaChevronLeft } from 'react-icons/fa'
import clsx from 'clsx'

import Collapse from '../../Collapse/Collapse'
import ShallowChild from './ShallowChild'

export const NestedChild = ({ children: item, expanded, location, wrapper }) => {
    const classes = useStyles(styles)

    const [open, setOpen] = useState(false)

    const isActive = () => {
        const { path } = item
        const { pathname } = location
        return path === pathname.slice(0, path.length)
    }

    const active = isActive()

    const rootStyle = clsx({
        [classes.root]: true,
        [classes.active]: active
    })

    const chevronStyle = clsx({
        [classes.chevron]: true,
        [classes.opened]: open
    })

    const renderChildren = () => {
        const { itens } = item
        const result = []
        itens.forEach(children => {
            const props = { children, expanded, location, wrapper }
            if (children.hasOwnProperty('itens') && children.itens.length)
                return result.push(<NestedChild  {...props} key={children.apelido} />)

            return result.push(<ShallowChild {...props} key={children.apelido} />)
        })
        return result
    }

    const { apelido } = item
    return (
        <>
            <div className={rootStyle} onClick={() => setOpen(!open)}>
                <div className={classes.label}>
                    {apelido}
                </div>
                <FaChevronLeft className={chevronStyle} />
            </div>
            <div className={classes.collapse}>
                <Collapse in={open && expanded}>
                    {renderChildren()}
                </Collapse>
            </div>
        </>
    )
}

export default NestedChild