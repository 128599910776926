import React, { useContext } from 'react'

import { context } from '../../components/Navbar/context'
import { NavLink as Link } from 'react-router-dom'

import { useStyles } from '..'
import styles from './jss/temporary'

import { Header, List } from '.'
import Overlay from '../Overlay/Overlay'

export const Temporary = ({ items }) => {
    const classes = useStyles(styles)
    const drawer = useContext(context)
    const { expanded, setExpanded } = drawer

    if (!expanded) return null

    const headerProps = {
        expanded
    }

    const listProps = {
        items,
        expanded,
        setExpanded,
        wrapper: Link
    }

    const cancelBubbling = e => e.stopPropagation()

    return (
        <Overlay onOverlayClick={() => setExpanded(false)}>
            <div className={classes.root} onClick={cancelBubbling}>
                <div className={classes.inner}>
                    <Header {...headerProps} />
                    <List {...listProps} />
                </div>
            </div>
        </Overlay>
    )
}