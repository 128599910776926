import { ProfileTypes as types } from '../types'

const initialState = {
    profiles: []
}

export const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_PROFILES:
            return fetchProfiles(state, action)

        default:
            return state
    }
}

const fetchProfiles = (state, action) => ({
    ...state,
    profiles: action.data
})