import React from 'react'

//Styles
import { makeStyles } from '@material-ui/core'
import styles from './jss/forgot'
import classnames from 'classnames'

const useStyles = makeStyles(styles)

const LoginForgot = ({ onClick }) => {
    const classes = useStyles()

    const loginForgotText = classnames({
        [classes.loginForgotText]: true
    })

    return (
        <div className={loginForgotText}>
            <h5>
                Esqueceu sua senha?
                <span className='link' onClick={onClick}>Clique aqui!</span>
            </h5>
        </div>
    )
}

export default LoginForgot