export default theme => ({
    root: {
        maxWidth: 85,
        fontSize: 10,
        fontWeight: 800,
        color: props => props.color === 'default' ? 'white' : theme.palette[props.color].text,
        padding: '2px 5px',
        borderRadius: '.2rem',
        textAlign: 'center',
        backgroundColor: props => props.color === 'default' ? '#aaaaaa' : theme.palette[props.color].main,
        border: props => props.color === 'default' ? `1px solid #444` : `1px solid ${theme.palette[props.color].dark}`
    },

})