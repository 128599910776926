import React from 'react'


import AnimateHeight from 'react-animate-height'


export const Collapse = ({ children, in: inProp }) => {
    return (
        <AnimateHeight height={inProp ? 'auto' : 0} duration={180} >
            {children}
        </AnimateHeight>
    )

}


export default Collapse

