import React, { useEffect, useRef } from 'react'
import { uniqueId } from '../../helpers';

export const ClickAwayListener = ({ children, onClickAway, ignore }) => {
    const container = useRef(null)
    const id = uniqueId('away-')


    useEffect(() => {
        const listener = event => {
            const { current: element } = container
            const { target } = event
            const { name } = target
            if (!element.contains(target) && !ignore.includes(name))
                onClickAway()
        }
        document.addEventListener('click', listener)

        return () => {
            document.removeEventListener('click', listener)
        }
    }, [onClickAway, ignore])


    return (
        <span ref={container} id={id}>
            {children}
        </span>
    )
}

ClickAwayListener.defaultProps = {
    ignore: []
}

export default ClickAwayListener