import React, { memo, useRef, useState, useCallback } from "react";
import clsx from "clsx";
import { CustomFilterOptions, CustomCloseListener, CustomTitle } from "..";
import { useStyles } from "../../hooks";
import styles from "./jss/customGroupComboBox";
import { uniqueId } from "../../helpers";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import intl from "react-intl-universal";

const FunctionComponent = (props) => {
  const {
    title = "",
    items = [],
    setItems = () => [],
    feminine = false,
    required = false,
    loading = false,
  } = props;
  const itemsRef = useRef([]);
  const classes = useStyles(styles, props);
  const comboBoxIdRef = useRef(uniqueId("ComboBox-"));
  const [showOptions, setShowOptions] = useState(() => false);

  itemsRef.current = items?.filter(({ listed }) => listed);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });
  const containerStyle = clsx({
    [classes.container]: true,
    [classes.focusedContainer]: showOptions,
  });

  const toggleOptions = () => {
    setShowOptions((showOptions) => !showOptions);
  };

  const closeOptionsAndFilters = () => {
    setShowOptions(false);
  };

  const renderLabel = () => {
    const selectedItems = itemsRef.current.filter(({ active }) => active);
    if (selectedItems.length === 1 && selectedItems[0])
      return selectedItems[0].label;

    if (!selectedItems.length || !itemsRef.current.length)
      return feminine
        ? intl.get("core.CustomFilterableComboBox.noneSelectedFeminine")
        : intl.get("core.CustomFilterableComboBox.noneSelected");

    if (selectedItems.length === itemsRef.current.length)
      return feminine
        ? intl.get("core.CustomFilterableComboBox.allSelectedFeminine")
        : intl.get("core.CustomFilterableComboBox.allSelected");

    return feminine
      ? `${selectedItems.length} ${intl.get(
          "core.CustomFilterableComboBox.selectedFeminine"
        )}`
      : `${selectedItems.length} ${intl.get(
          "core.CustomFilterableComboBox.selected"
        )}`;
  };

  // const toggleAll = useCallback(
  //   ({ active }) => {
  //     const firstListedId = itemsRef.current[0].id;
  //     let updatedItemsRef = [];
  //     let updatedItems = [];

  //     items.forEach((item) => {
  //       const updatedItem = {
  //         ...item,
  //         active:
  //           required && item.id === firstListedId && active
  //             ? true
  //             : item.listed && !active,
  //       };

  //       if (item.listed) updatedItemsRef.push(updatedItem);

  //       updatedItems.push(updatedItem);
  //     });

  //     itemsRef.current = updatedItemsRef;
  //     setItems(updatedItems);
  //   },
  //   [items]
  // );

  const handleClickMultiSelectFilter = useCallback(
    ({ id, active }) => {
      const totalSelectedItems = itemsRef.current.filter(({ active }) => active)
        .length;
      let updatedItemsRef = [];
      let updatedItems = [];

      items.forEach((item) => {
        const updatedItem = {
          ...item,
          active:
            item.id === id
              ? (required && totalSelectedItems === 1) || !active
              : item.active,
        };

        if (item.listed) updatedItemsRef.push(updatedItem);

        updatedItems.push(updatedItem);
      });

      itemsRef.current = updatedItemsRef;
      setItems(updatedItems);
    },
    [items]
  );

  return (
    <div className={rootStyle}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.title,
          }}
          title={title}
        />
      )}
      <label htmlFor={comboBoxIdRef.current} className={containerStyle}>
        <div
          id={comboBoxIdRef.current}
          className={classes.labelContainer}
          onClick={toggleOptions}
        >
          {renderLabel()}
        </div>
        <div className={classes.caretContainer} onClick={toggleOptions}>
          {showOptions ? <FaCaretUp /> : <FaCaretDown />}
        </div>
      </label>
      <>
        {showOptions && (
          <CustomCloseListener onClose={closeOptionsAndFilters}>
            <CustomFilterOptions
              items={itemsRef.current}
              handleClick={handleClickMultiSelectFilter}
              loading={loading}
            />
          </CustomCloseListener>
        )}
      </>
    </div>
  );
};
export const CustomGroupComboBox = memo(FunctionComponent);

export default CustomGroupComboBox;
