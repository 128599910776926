import colorNames from './colorNames'

const preset = ['primary', 'success', 'danger', 'warning', 'default']

export const isPreset = color => preset.includes(color)

export const colorByName = color => colorNames[color] ? colorNames[color] : color

export const hexToRgb = hex => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

    hex = colorByName(hex)

    hex = hex.replace(shorthandRegex, (_, r, g, b) => {
        return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null
}

export const getLuminance = ({ r, g, b }) => ((0.299 * r) + ((0.587 * g) + (0.114 * b)))

export const getBackgroundColor = color => theme => {
    if (isPreset(color)) return theme.palette[color].main
    return color
}

export const getTextColor = color => theme => {
    if (isPreset(color)) return theme.palette[color].text

    return getLuminance(hexToRgb(color)) > 186 ? '#000' : '#fff'
}

export const getColor = color => theme => {
    if (isPreset(color)) return theme.palette[color].main

    return colorByName(color)
}

export const toRgba = (hex, opacity = 1) => {
    hex = colorByName(hex)
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

    hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b)

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (!result) return 'rgba(255,255,255, 0)'

    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

