import { AuthTypes as types, UserTypes, SearchbarTypes } from "../types";
import axios from "axios";

import { setSnackbar } from "./Snackbar";

const { REACT_APP_API: path } = process.env;

export const loginAttempt = (credentials) => {
  return (dispatch) => {
    return new Promise((_, reject) => {
      const endpoint = `${path}/auth`;
      const { email, password } = credentials;
      const payload = { email, senha: password };
      axios
        .post(endpoint, payload)
        .then((res) => {
          const { data } = res;
          localStorage.setItem("token", data.token);
          dispatch({ type: types.STORE_TOKEN, data });
        })
        .catch((err) => reject(err));
    });
  };
};

export const fetchSetup = () => {
  return (dispatch, getState) => {
    const { token, id } = getState().auth;
    const headers = { headers: { Authorization: `bearer ${token}` } };

    const promises = [
      fetchMenu(headers),
      fetchCompanies(headers),
      fetchPermissions(headers),
      fetchUser(headers, id),
    ];

    Promise.all(promises)
      .then((res) => {
        if (!res[1].length || !res[2].length)
          return dispatch({ type: "LOGOUT" });

        dispatch({ type: types.STORE_MENU, data: res[0] });
        dispatch({ type: types.STORE_COMPANIES, data: res[1] });
        dispatch({ type: types.STORE_PERMISSIONS, data: res[2] });
        dispatch({ type: UserTypes.STORE_USER, data: res[3] });
        dispatch(setSnackbar({ open: false }));
        fetchSearchbar(headers, res[1])
          .then((res) =>
            dispatch({ type: SearchbarTypes.STORE_SEARCHBAR, data: res })
          )
          .catch((err) => console.error(err));

        // fetchPreferences(headers, res[1])
        //   .then((res) =>
        //     dispatch({ type: CardTypes.STORE_PREFERENCES, data: res })
        //   )
        //   .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };
};

export const updateCurrentUser = (headers, id) => {
  return (dispatch) => {
    const promises = [fetchUser(headers, id)];

    Promise.all(promises)
      .then((res) => {
        dispatch({ type: UserTypes.STORE_USER, data: res[0] });
      })
      .catch((err) => console.error(err));
  };
};

const fetchMenu = (headers) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/permissaoperfil/menu`;
    axios
      .get(endpoint, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const fetchPermissions = (headers) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/permissaoperfil`;
    axios
      .get(endpoint, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const fetchCompanies = (headers) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/empresa/resumo`;
    axios
      .get(endpoint, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const fetchUser = (headers, id) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/usuario/${id}`;
    axios
      .get(endpoint, headers)
      .then((res) => resolve(res.data[0]))
      .catch((err) => reject(err));
  });
};

const fetchSearchbar = (headers, selectedCompanies) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/veiculo/resumo`;
    const payload = {
      empresasSelecionadas: selectedCompanies.map((x) => x.id),
    };
    axios
      .post(endpoint, payload, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const refreshSearchbar = () => {
  return (dispatch, getState) => {
    const {
      auth: { token, selectedCompanies },
    } = getState();
    const headers = { headers: { Authorization: `bearer ${token}` } };
    fetchSearchbar(headers, selectedCompanies)
      .then((res) => {
        dispatch({ type: SearchbarTypes.REFRESH_SEARCHBAR, data: res });
      })
      .catch((err) => console.error(err));
  };
};

export const recycleToken = (token) => {
  return (dispatch) => {
    if (!token) return;
    const endpoint = `${path}/validate`;
    const payload = { token };
    axios
      .post(endpoint, payload)
      .then((res) => {
        const {
          data: { auth, expirarToken, ...newValues },
        } = res;

        if (!auth || expirarToken) {
          dispatch({ type: "LOGOUT" });
          return window.location.reload();
        }

        //adiciona o token com expiração atualizada
        localStorage.setItem("token", newValues.token);
        dispatch({ type: types.STORE_TOKEN, data: newValues });
      })
      .catch((err) => console.error(err));
  };
};

export const selectCompany = (id) => {
  return (dispatch, getState) => {
    const { companies, selectedCompanies, token } = getState().auth;
    const headers = { headers: { Authorization: `bearer ${token}` } };

    if (selectedCompanies.map((x) => x.id).includes(id)) {
      if (selectedCompanies.length === 1) return;

      const companies = selectedCompanies.filter((x) => x.id !== id);
      dispatch({
        type: types.SELECT_COMPANY,
        data: companies,
      });
      dispatch(refreshSearchbar(headers, companies));
    } else {
      const companiesConcat = selectedCompanies.concat(
        companies.find((x) => x.id === id)
      );
      dispatch({
        type: types.SELECT_COMPANY,
        data: companiesConcat,
      });
      dispatch(refreshSearchbar(headers, companiesConcat));
    }
  };
};
