import React, { useRef, useContext } from 'react'
import { context } from '../../styles/theme/context'

import { useStyles } from '..'
import styles from './jss/basic'
import { Ripple } from '../Ripple'
import clsx from 'clsx'
import { isPresetColor } from '../../utils/helpers'

const getRippleColor = (color, theme) => {
    if (isPresetColor(color) && theme) return theme.palette[color].main
    return color
}

export const Basic = ({
    children,
    className,
    style,
    color,
    rippleColor,
    stroked,
    disabled,
    ...buttonProps
}) => {
    const interpolation = { color, rippleColor }
    const classes = useStyles(styles, interpolation)
    const self = useRef(null)
    const { theme } = useContext(context)

    const rootStyle = clsx({
        [classes.root]: true,
        [classes[color]]: isPresetColor(color),
        [classes.stroked]: stroked,
        [classes.disabled]: disabled
    })

    const buttonStyle = `${rootStyle} ${className}`

    return (
        <button className={buttonStyle} style={style} ref={self} {...buttonProps}>
            <Ripple
                container={self}
                color={rippleColor ? rippleColor : getRippleColor(color, theme)}
            />
            {children}
        </button>
    )
}

Basic.defaultProps = {
    className: '',
    style: {},
    color: 'primary',
    stroked: false,
    disabled: false
}
