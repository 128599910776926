import React from 'react'

import { useStyles } from '..'
import styles from './jss/header'

const logo = require('../../assets/images/icon-little.png')

export const Header = ({ title, expanded, initials, favicon }) => {
    const classes = useStyles(styles)

    const getTitle = () => {
        if (expanded || title.length < 3) return title

        if (initials) return initials

        return title.slice(0, 2).toUpperCase()
    }

    return (
        <div className={classes.root}>
            {expanded && <div className={classes.title}>{getTitle()}</div>}
            {!expanded && Boolean(favicon) && <img src={favicon} alt='logo' className={classes.logo}/>}
        </div>
    )
}

Header.defaultProps = {
    initials: 'CT',
    title: 'Chiptronic',
    favicon : logo
}

export default Header