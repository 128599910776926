export default theme => ({
    root: {
        padding: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        whiteSpace : 'nowrap'
    },

    description: {
        fontSize: 13,
        color: '#444444',
        fontWeight: 700,
        
    },

    maker: {
        fontSize: 10,
        fontWeight: 800,
        padding: '1px 5px',
        color: '#444444',
        border: '1px solid #cecece',
        textAlign: 'center',
        marginLeft: 5
    },

    grow: {
        flexGrow: 1
    }
})