import React from "react";
import { useStyles } from "../../hooks";
import clsx from "clsx";
import { FaCheck } from "react-icons/fa";

import styles from "./jss/CustomOptionsItem";

const FunctionComponent = (props) => {
  const { item = {}, handleClick = () => {} } = props;

  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
    [classes.hover]: true,
  });

  return (
    <div className={rootStyle} onClick={() => handleClick(item)}>
      <div className={classes.container}>
        <span className={classes.label}>{item.label}</span>
        {item.details && (
          <span className={classes.details}>{` ${item.details}`}</span>
        )}
      </div>
      {item.active ? (
        <div className={classes.icon}>
          <FaCheck />
        </div>
      ) : null}
    </div>
  );
};

export const CustomOptionsItem = React.memo(FunctionComponent);

export default CustomOptionsItem;
