import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mdb: 768,
    },
  },
  typography: {
    standard: {
      fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontSize: "1rem",
      fontSizeXs: "0.66rem",
      fontSizeError: "13px",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      fontWeightExtraBold: 700,
      letterSpacing: "0.00938rem",
      lineHeight: "0.1rem",
    },
  },
  palette: {
    primary: {
      main: "#002984",
      light: "#193e90",
      dark: "#154D75",
      text: "#ffffff",
    },
    secondary: {
      main: "#565655",
    },
    accent: {
      main: "#002984",
    },
    success: {
      main: "#4BB543",
      dark: "#347e2e",
      text: "#ffffff",
    },
    danger: {
      main: "#dc001c",
      dark: "#9a0013",
      text: "#ffffff",
    },
    warning: {
      main: "#ffc247",
      dark: "#b28731",
      text: "#ffffff",
    },
    default: {
      main: "#d0d0d0",
      dark: "#919191",
      text: "#444444",
    },
    background: {
      paper: "#ffffff",
    },
    container: {
      background: "#ffffff",
    },
    button: {
      light: "#ffffff",
      dark: "#444444",
    },
    text: {
      primary: "#444444",
      accent: "#ffffff",
      success: "#ffffff",
      danger: "#ffffff",
      warning: "#394047",
      default: "#394047",
    },
  },
  drawer: {
    noWidth: 0,
    width: 50,
    expandedWidth: 230,
    background: "#313947",
    color: "#a6b2c1",
    colorHover: "white",
    transition: (property) => `${property} 200ms ease`,
  },
  navbar: {
    height: 60,
    background: "white",
    color: "#313947",
  },
  transitions: {
    drawer: (property) => `${property} 200ms ease`,
  },
  zIndex: {
    appBar: 1200,
    scrollShadow: 1225,
    drawer: 2100,
    multiSelect: 1500,
    overlay: 1250,
  },
  dimensions: {
    customHeight: {
      full: "100%",
      fullWithNavBar: "calc(100% - 60px)",
      fullWithNavBarTabs: "calc(100% - 108px)",
      fullWithNavBarFilters: "calc(100vh - 168px)",
      medium: 55,
      navBar: 60,
      navFilters: 78,
      navFilters2: 146,
      navTabs: 48,
      small: 40,
      xScroll: 10,
    },
    customWidth: {
      full: "100%",
      fullWithDrawer: "calc(100% - 50px)",
    },
    customMargin: {
      none: 0,
      xSmall: 3,
      small: 5,
      medium: 8,
      large: 12,
      xLarge: 15,
    },
    customBorder: {
      none: 0,
      small: 1,
      medium: 2,
      large: 3,
      xLarge: 4,
    },
    customBorderRadius: {
      none: 0,
      default: "0.7rem",
      xSmall: 3,
      small: 5,
      medium: 8,
      large: 12,
      xLarge: 15,
    },
    customPadding: {
      none: 0,
      xSmall: 3,
      small: 5,
      medium: 8,
      large: 12,
      xLarge: 15,
    },
  },
  elevation: {
    0: "none",
    1: "0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)",
    2: "0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)",
    3: "0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)",
    4: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    5: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    6: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    7: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    8: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    9: "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    10: "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
  },
});
