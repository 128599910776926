export default (theme) => ({
  root: {
    width: "100%",
    display: "block",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
    minHeight: theme.dimensions.customHeight.small,
    // borderColor: theme.palette.text.secondary,
    // borderStyle: "solid",
    // borderWidth: theme.dimensions.customBorder.small,
    // borderRadius: theme.dimensions.customBorderRadius.small,
    fontFamily: "inherit",
    fontSize: 12,
    fontWeight: "inherit",
    letterSpacing: "inherit",
    color: "inherit",
    overflow: "hidden",
    outline: "none",
    // "&:hover": {
    //   borderColor: theme.palette.text.primary,
    // },
  },
  font: {
    fontSize: 12,
  },
  help: {
    display: "flex",
    alignItems: "center",
    marginLeft: 0,
    padding: 6,
    "& > svg": {
      color: "#444444",
    },
  },
});
