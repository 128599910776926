import React from "react";
import { useSelector } from "react-redux";

//Styles
import { makeStyles } from "@material-ui/core";
import styles from "./jss/loginForgot";
 
//Material UI components
import { Dialog, Button } from "@material-ui/core";
import { InputLogin, Snackbar } from "../../core";

const useStyles = makeStyles(styles);
const logo = require("../../assets/images/logo.png");

const LoginForgotDialog = ({ open, openForgot, state, onChange, onSubmit }) => {
  const classes = useStyles();

  const snackbar = useSelector((s) => s.snackbar.snackbar);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  };

  return (
    <Dialog open={open} onClose={() => openForgot(false)}>
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt="logo" />
        </div>
        <div className={classes.form}>
          <InputLogin
            type="email"
            value={state.email}
            onChange={onChange}
            name="email"
            className={classes.input}
            label="Email"
            onKeyDown={onKeyDown}
            autoFocus
          />
          
          <div className={classes.buttonContainer}>
            <Button
              className={classes.backButton}
              color="primary"
              onClick={() => openForgot(false)}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onSubmit}
            >
              Recuperar Senha
            </Button>
          </div>
        </div>
        <Snackbar {...snackbar} />
      </div>
    </Dialog>
  );
};

export default LoginForgotDialog;
