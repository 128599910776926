export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    body: {
        flex: 1,
        padding: 5,
        marginTop: 20,
        minHeight: 50
    },

    grow: {
        flexGrow: 1
    },

    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    button: {
        marginLeft: 10
    }
})