import React from 'react'

import { useStyles } from '..'
import styles from './jss/header'
import Status from './Status';

export const Header = ({ descricao, ignicao, automaker }) => {
    const classes = useStyles(styles)
    return (
        <div className={classes.root}>
            <div className={classes.description}>
                {descricao}
            </div>
            <div className={classes.maker}>
                {(automaker && automaker.label) ? automaker.label : ''}
            </div>
            <div className={classes.grow} />
            <Status ignicao={ignicao} />
        </div>
    )
}

export default Header