import React, { useState } from 'react'

import { useStyles } from '..'
import styles from './jss/wave'

export const Wave = ({ top, left, radius, color, icon }) => {
    const [render, setRender] = useState(true)

    const interpolation = { top, left, radius, color, icon }
    const classes = useStyles(styles, interpolation)

    if (!render) return null

    return <span
        className={classes.root}
        onAnimationEnd={() => setRender(false)}
    />
}