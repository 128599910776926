export default (theme) => ({
  root: {
    "& > *": {
      backgroundColor: theme.palette.background.paper,
      width: theme.dimensions.customWidth.full,
      minHeight: theme.dimensions.customHeight.small,
      borderColor: theme.palette.text.secondary,
      borderStyle: "solid",
      borderWidth: theme.dimensions.customBorder.small,
      borderRadius: theme.dimensions.customBorderRadius.small,
      fontFamily: "inherit",
      fontSize: 12,
      fontWeight: "inherit",
      letterSpacing: "inherit",
      overflow: "hidden",
      outline: "none",
      "&:hover": {
        borderColor: theme.palette.text.primary,
      },
    },
  },
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  customTitleContainer: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: 12,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
  },
});
