import React, { useState, useEffect } from 'react'

import { createPortal } from 'react-dom'

import { useStyles } from '..'
import styles from './styles'
import clsx from 'clsx'

import { Paper, Button } from '..'
import { FaTimes } from 'react-icons/fa';


export const Snackbar = ({ open, onClose, title, icon: Icon, color, duration }) => {

    const interpolation = { color }
    const classes = useStyles(styles, interpolation)

    const [render, setRender] = useState(open)
    const [animateOut, setAnimateOut] = useState(false)

    const rootStyle = clsx({
        [classes.root]: true,
        [classes.retract]: animateOut
    })

    useEffect(() => {
        let timeout
        if (open) {
            setRender(true)
            timeout = setTimeout(onClose, duration)
        }
        return () => clearTimeout(timeout)

    }, [open, duration, onClose])

    useEffect(() => {
        let timeout

        if (!open) {
            setAnimateOut(true)
            timeout = setTimeout(() => requestAnimationFrame(() => {
                setRender(false)
                setAnimateOut(false)
            }), 200)
        }
        return () => clearTimeout(timeout)
    }, [open])

    const onClick = () => onClose()

    if (!render && !open) return null

    return createPortal(
        <Paper className={rootStyle}>
            {
                Icon && (
                    <div className={classes.iconContainer}>
                        <Icon className={classes.icon} />
                    </div>
                )
            }
            <div className={classes.title}>{title}</div>
            <div className={classes.grow} />
            <Button variant='icon' color='#fff' rippleColor='#fff' onClick={onClick}>
                <FaTimes />
            </Button>
        </Paper>, document.getElementById('root')
    )
}

Snackbar.defaultProps = {
    title: '',
    icon: null,
    color: 'primary',
    open: false,
    onClose: () => { },
    duration: 3000
}

export default Snackbar