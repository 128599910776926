const form = (intl) => ({
    senha: {
        value: '',
        valid: true,
        rules: null,
        variant: 'simple',
        label: intl.get('personalprofile.current_pass'),
        name: 'senha',
        type: 'password'
    },
    novaSenha: {
        value: '',
        valid: true,
        rules: {
            minLength: 4,
            maxLength: 14
        },
        variant: 'simple',
        label: intl.get('personalprofile.new_pass.label'),
        name: 'novaSenha',
        type: 'password',
        errorMessage: intl.get('personalprofile.new_pass.error')
    },
    confirmacaoSenha: {
        value: '',
        valid: true,
        rules: {
            equalsTo: 'novaSenha'
        },
        variant: 'simple',
        label: intl.get('personalprofile.confirm.label'),
        name: 'confirmacaoSenha',
        type: 'password',
        errorMessage: intl.get('personalprofile.confirm.error')
    }

})

export default form