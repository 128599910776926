import React from 'react'

import { useStyles, Button } from '..'
import styles from './jss/confirmDialog'

import { Dialog } from '.'

export const ConfirmDialog = ({ message, actions, onClose, ...rest }) => {
    const classes = useStyles(styles)

    const handleClick = action => {
        action()
        onClose()
    }

    const renderActions = () => {
        return actions.map(action => (
            <Button key={action.id} onClick={() => handleClick(action.action)} color={action.color} className={classes.button}>
                {action.title}
            </Button>
        ))
    }

    return (
        <Dialog onClose={onClose}{...rest}>
            <div className={classes.root}>
                <div className={classes.body}>
                    {message}
                </div>
                <div className={classes.grow} />
                <div className={classes.footer}>
                    {renderActions()}
                </div>
            </div>
        </Dialog>
    )
}

ConfirmDialog.defaultProps = {
    message: '',
    onClose: () => { }
}

export default ConfirmDialog