import {
  FaHome,
  FaGlobe,
  FaTools,
  FaFileInvoice,
  FaDollyFlatbed,
  FaGasPump,
  FaUserClock,
  FaShareAlt,
  FaUserShield,
  FaTractor,
  FaCogs,
  FaTrophy,
  FaStopwatch,
} from "react-icons/fa";

import { MdDashboard } from "react-icons/md";

export const icons = {
  home: FaHome,
  dashboard: MdDashboard,
  globe: FaGlobe,
  tools: FaTools,
  report: FaFileInvoice,
  trophy: FaTrophy,
  dollyFlatbed: FaDollyFlatbed,
  gaspump: FaGasPump,
  userclock: FaUserClock,
  sharealt: FaShareAlt,
  usershield: FaUserShield,
  tractor: FaTractor,
  cogs: FaCogs,
  stopWatch: FaStopwatch,
};
