import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const FunctionComponent = ({
  title,
  labelPlacement = "start",
  color = "primary",
  checked,
  setChecked,
  disabled = false,
}) => {
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <FormControlLabel
      value={title}
      style={{ margin: 0 }}
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          color={color}
          inputProps={{ "aria-label": "secondary checkbox" }}
          disabled={disabled}
        />
      }
      label={title}
      labelPlacement={labelPlacement}
    />
  );
};

export const CustomCheckBox = React.memo(FunctionComponent);

export default CustomCheckBox;
