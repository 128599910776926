import React, { useContext } from "react";
import { useStyles } from "../../hooks";
import { context } from "../../components/Navbar/context";
import clsx from "clsx";

import styles from "./jss/CustomWrapper";

const FunctionComponent = (props) => {
  const {
    classe,
    children,
    rootRef = null,
    withNavBar = true,
    withNavTabs = false,
    withNavFilters = false,
    withNavFilters2 = false,
    withPanel = true,
  } = props;
  const { variant, expanded, locked } = useContext(context);
  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.withNavBar]: withNavBar,
    [classes.withNavBarTabs]: withNavBar && withNavTabs,
    [classes.withNavBarFilters]: withNavBar && withNavFilters,
    [classes.withNavBarFilters2]: withNavBar && withNavFilters2,
    [classes.temporary]: variant === "temporary",
    [classes.permanent]: variant === "permanent",
    [classes.expanded]: variant === "permanent" && expanded && !locked,
    [classes.locked]: variant === "permanent" && expanded && locked,
  });

  return (
    <div ref={rootRef} className={rootStyle}>
      <div className={classes.shadowTop} />
      <div className={classes.container}>
        {withPanel ? (
          <div className={classe ? classe : classes.panel}>{children}</div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export const CustomWrapper = React.memo(FunctionComponent);

export default CustomWrapper;
