import { getColor } from "../../../utils/helpers";

export default theme =>({
    root: ({ top, left, radius, color }) => ({
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        '&:active': {
            '&:after': {
                position: 'absolute',
                top: `calc(${top}% - ${radius / 2}px)`,
                left: `calc(${left}% - ${radius / 2}px)`,
                height: radius,
                width: radius,
                borderRadius: '50%',
                backgroundColor: getColor(color, theme),
                content: "' '",
                animation: '$rippleActive .8s',
                animationTimingFunction: 'cubic-bezier(.25, .46, .45, .94)',
                transform: 'scale(5)',
                opacity: ({ icon }) => icon ? .5 : .15,
            }
        },
    }),

    '@keyframes rippleActive': {
        from: {
            transform: 'scale(0)',
            opacity: 0
        },
        to: {
            transform: 'scale(5)',
            opacity: .15
        }
    },

    '@keyframes rippleIcon': {
        from: {
            transform: 'scale(0)',
            opacity: 0
        },
        to: {
            transform: 'scale(5)',
            opacity: .5
        }
    }
})

