import { useEffect, useState } from 'react'

const { REACT_APP_GOOGLE_KEY: key } = process.env

export const useMapLoader = ({ configs }) => {
    const [map, setMap] = useState(null)
    useEffect(() => {
        const { google } = window
        const onLoad = () => {
            const { google } = window
            const map = new google.maps.Map(document.getElementById('core-map'), {
                zoom: 3,
                center: { lat: -28.024, lng: 140.887 }
            }
            )
            setMap(map)
        }
        if (!google) {
            const script = document.createElement("script")
            script.src = `https://maps.googleapis.com/maps/api/js?key=${key}`
            script.async = true
            script.onload = onLoad
            document.body.appendChild(script)
        }
    }, [configs])

    return { map, google: window.google }
}