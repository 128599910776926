export default theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    status:{
        fontSize: 14,
        color: theme.palette.default.text,
        fontWeight: 600,
        marginTop: 15,
    }
})