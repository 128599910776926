import { useEffect, useContext, useMemo } from 'react'
import { createSheet } from '../jss'
import { context } from '../theme'


const isFunc = arg => typeof arg === 'function'

const getStyles = styles => theme => isFunc(styles) ? styles(theme) : styles

export const useStyles = (styles, interpolation = null) => {
    const theme = useContext(context)
    
    const sheet = useMemo(() => createSheet(getStyles(styles)(theme)), [styles, theme])
    sheet.attach()

    useEffect(() => {
        return () => sheet.detach()
    }, [sheet])

    useEffect(() => {
        if (interpolation)
            sheet.update(interpolation)


    }, [sheet, interpolation])


    return sheet.classes
}

