import React, { useRef, useCallback, useContext } from "react";
import { format } from "date-fns";

const ReportsVehicleRefContext = React.createContext();
const ReportsVehiclesRefContext = React.createContext();
const ReportsVehiclesFiltersRefContext = React.createContext();
const ReportsStartDateRefContext = React.createContext();
const ReportsEndDateRefContext = React.createContext();
const ReportsFetchedVehiclesAndFilterContext = React.createContext();

const ContextProvider = (props) => {
  const { children } = props;
  const currentDateRef = useRef(new Date());
  const firstFetchRef = useRef(true);
  const initiallySelectedRef = useRef(true);
  const initiallyListedRef = useRef(true);
  const vehiclesRef = useRef([]);
  const vehicleRef = useRef();
  const vehiclesFiltersRef = useRef([]);
  const startDateRef = useRef(
    format(currentDateRef.current - 86400000, "yyyy-MM-dd'T'HH:mm")
  );
  const endDateRef = useRef(
    format(currentDateRef.current, "yyyy-MM-dd'T'HH:mm")
  );

  const fetchedVehiclesAndFiltersCallback = useCallback(
    (vehicles, vehiclesFilters) => {
      const inactiveFilters = vehiclesFiltersRef.current.filter(
        ({ filterParameter, active }) => filterParameter && !active
      );
      const inactiveFiltersIds = inactiveFilters.map(({ id }) => id);
      const selectedVehiclesIds = vehiclesRef.current
        .filter(({ active }) => active)
        .map(({ id }) => id);

      if (vehiclesFilters)
        vehiclesFiltersRef.current = vehiclesFilters.map((vehicleFilter) => ({
          ...vehicleFilter,
          active: firstFetchRef.current
            ? initiallyListedRef.current
            : !inactiveFiltersIds.includes(vehicleFilter.id),
        }));
      else vehiclesFiltersRef.current = [];

      if (vehicles)
        vehiclesRef.current = vehicles.map((vehicle) => {
          const listed = !inactiveFilters.some(
            ({ filterParameter, filterValue }) =>
              vehicle[filterParameter] === filterValue
          );

          return {
            ...vehicle,
            active: firstFetchRef.current
              ? initiallyListedRef.current && initiallySelectedRef.current
              : listed && selectedVehiclesIds.includes(vehicle.id),
            listed: firstFetchRef.current ? initiallyListedRef.current : listed,
          };
        });
      else vehiclesRef.current = [];

      firstFetchRef.current = false;
    },
    []
  );

  return (
    <ReportsVehiclesRefContext.Provider value={vehiclesRef}>
      <ReportsVehicleRefContext.Provider value={vehicleRef}>
        <ReportsVehiclesFiltersRefContext.Provider value={vehiclesFiltersRef}>
          <ReportsFetchedVehiclesAndFilterContext.Provider
            value={fetchedVehiclesAndFiltersCallback}
          >
            <ReportsStartDateRefContext.Provider value={startDateRef}>
              <ReportsEndDateRefContext.Provider value={endDateRef}>
                {children}
              </ReportsEndDateRefContext.Provider>
            </ReportsStartDateRefContext.Provider>
          </ReportsFetchedVehiclesAndFilterContext.Provider>
        </ReportsVehiclesFiltersRefContext.Provider>
      </ReportsVehicleRefContext.Provider>
    </ReportsVehiclesRefContext.Provider>
  );
};

export const useVehicleRef = () => {
  return useContext(ReportsVehicleRefContext);
};

export const useVehiclesRef = () => {
  return useContext(ReportsVehiclesRefContext);
};

export const useVehiclesFiltersRef = () => {
  return useContext(ReportsVehiclesFiltersRefContext);
};

export const useStartDateRef = () => {
  return useContext(ReportsStartDateRefContext);
};

export const useEndDateRef = () => {
  return useContext(ReportsEndDateRefContext);
};

export const useFetchedVehiclesAndFiltersCallback = () => {
  return useContext(ReportsFetchedVehiclesAndFilterContext);
};

export const ReportsContextProvider = ContextProvider;

export default ReportsContextProvider;
