import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { context } from "../../components/Navbar/context";
import { Drawer, Appbar, Companies, User } from "../../core";


export const Navbar = () => {
  const auth = useSelector((s) => s.auth);
  const drawer = useContext(context);
  const { variant } = drawer;

  const {
    menuPermissions: items,
    companies,
    selectedCompanies: selected,
  } = auth;

  return (
    <>
      <Appbar variant={variant}>
        <Companies companies={companies} selected={selected} />
        <User />
      </Appbar>

      <Drawer items={items} variant={variant} />
    </>
  );
};

export default Navbar;
