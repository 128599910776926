import React, { useContext } from 'react'
import { context } from '../../components/Navbar/context'

import { NavLink as Link } from 'react-router-dom'

import { useStyles } from '..'
import styles from './jss/permanent'
import clsx from 'clsx'

import { Header, List } from '.'

export const Permanent = ({ items }) => {
    const classes = useStyles(styles)

    const drawer = useContext(context)

    const { expanded, setExpanded } = drawer


    const rootStyle = clsx({
        [classes.root]: true,
        [classes.expanded]: expanded
    })

    const onEnter = () => setExpanded(true)
    const onLeave = () => setExpanded(false)

    const headerProps = {
        expanded
    }

    const listProps = {
        items,
        expanded,
        setExpanded,
        wrapper: Link
    }

    return (
        <div className={rootStyle} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            <div className={classes.inner}>
                <Header {...headerProps} />
                <List {...listProps} />
            </div>
        </div>
    )
}

export default Permanent