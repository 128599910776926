export default theme => ({
    root: {
        height: 30,
        backgroundColor: 'white',
        margin: 5,
        borderRadius: '5px',
        padding: 5,
        boxShadow: theme.elevation[1],
        display: 'flex',
        alignItems: 'center',
    },

    main: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
        margin: '0 5px',
    },

    chevron: {
        color: theme.drawer.background,
        margin: '0 5px',
        fontSize: 12
    },

    icon: {
        fontSize: 22,
        color: theme.drawer.background
    },

    crumb: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.default.text,
        cursor: 'pointer',
        margin: '0 5px',
    }
})