export default theme => ({
    customButton: {
        position: 'absolute',
        height: 36,
        width: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 10,
        left: 183,
        direction: 'ltr',
        textAlign: 'center',
        color: 'black',
        fontFamily: "Roboto, sans-serif",
        fontSize: '17px !important',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '1px 6px',
        borderBottomLeftRadius: '2px',
        borderTopLeftRadius: '2px',
        backgroundClip: 'padding-box',
        border: '1px solid rgba(238, 238, 238, 0.14902)',
        boxShadow: 'rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px',
        minWidth: '28px',
        fontWeight: 500,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgb(235,235,235)'
        },
        '&:active': {
            backgroundColor: 'rgb(200,200,200)'
        }
    },

    loadingElement: {
        height: '100%'
    },

    container: {
        height: props => props.height,
        position: 'relative',
        zIndex: 1900
    },

    map: {
        height: `100%`,
        zIndex: 1900,
        borderRadius: '5px',
        boxShadow: theme.elevation[1],
        
    }
})