import React from 'react'

import { useStyles } from '..'
import styles from './jss/vehicle'
import Paper from '../Paper/Paper'
import Header from './Header'
import Icon from './Icon'
import Indicators from './Indicators'
import Footer from './Footer'

import { SortableElement } from 'react-sortable-hoc'
import { HorizontalVehicle } from './HorizontalVehicle'

export const Vehicle = SortableElement(({ vehicle, automakers, telemetryPermission, horizontal, setDtc }) => {
    const classes = useStyles(styles)
    
    const {
        id,
        descricao,
        ignicao,
        rssi,
        status_dtc_presente: falhas,
        temp_liquido_arref: temperatura,
        status_cinto: cinto,
        tensao_bateria: tensao,
        status_pisca_alerta: pisca,
        rotacao,
        acelerador,
        velocidade_atual: velocidade,
        nivel_combustivel: combustivel,
        odometro,
        motorista,
        data,
        evento,
        status,
        id_montadora,
        id_categoria
    } = vehicle

    const indicators = {
        rssi,
        falhas,
        temperatura,
        motorista,
        tensao,
        cinto,
        evento,
        pisca,
        acelerador,
        data,
        rotacao,
        telemetria: telemetryPermission,
        id_montadora,
        setDtc
    }

    const footer = {
        velocidade,
        combustivel,
        odometro,
        telemetria: telemetryPermission,
    }
    
    const automaker = automakers.find(x => x.id === id_montadora)

    if (horizontal) return <HorizontalVehicle />

    return (
        <Paper className={classes.root}>
            <Header descricao={descricao} ignicao={ignicao} automaker={automaker} />
            <div className={classes.body}>
                <div className={classes.leftPanel}>
                    <Icon id={id} ignicao={ignicao} status={status} categoria={id_categoria} />
                    
                </div>
                <div className={classes.rightPanel}>
                    <Indicators {...indicators} />
                </div>
            </div>
            <Footer {...footer} />
        </Paper>
    )
})

export default Vehicle