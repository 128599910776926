const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightLight,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.default.dark,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.standard.fontSizeXs,
    },
  },
  statusText: {
    marginTop: theme.dimensions.customMargin.large,
    fontSize: "4em",
  },
  redirect: {
    marginTop: theme.dimensions.customMargin.large,
    fontSize: "1.5em",
    color: theme.palette.primary.light,
    cursor: "pointer",
  },
});

export default styles;
