const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  container: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    width: "100%",
    overflow: "hidden",
  },
  counter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: 40,
    boxSizing: "borderBox",
    borderRadius: theme.dimensions.customBorderRadius.default,
    background: "rgb(49, 57, 71)",
    color: "white",
    fontSize: "1.1em",
  },
  counterTooltip: {
    background: "rgb(49, 57, 71)",
    fontSize: "1.0em",
    fontFamily: theme.typography.standard.fontFamily,
  },
  alertCounter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    width: 40,
    boxSizing: "borderBox",
    borderRadius: theme.dimensions.customBorderRadius.default,
    background: "rgb(255, 50, 50)",
    color: "white",
    fontSize: "1.1em",
  },
  alertCounterTooltip: {
    background: "rgb(255, 50, 50)",
    fontSize: "1.0em",
    fontFamily: theme.typography.standard.fontFamily,
  },
});

export default styles;
