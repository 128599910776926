import React from 'react'

import { uniqueId } from '../../helpers'

import { useStyles } from '../../styles'
import styles from './jss/inputLogin'
import clsx from 'clsx'

export const InputLogin = ({ className, value, label, id, valid, errorMessage, ...rest }) => {
    const classes = useStyles(styles)
    id = !id ? uniqueId() : id

    const rootStyle = clsx({
        [classes.root]: true
    })

    const inputStyle = clsx({
        [classes.input]: true
    })

    return (
        <div className={`${rootStyle} ${className}`}>
            <input
                className={inputStyle}
                id={id}
                placeholder={label}
                value={value}
                {...rest}
            />
            <span />
        </div>
    )
}

InputLogin.defaultProps = {
    label: '',
    value: '',
    className: ''
}

export default InputLogin