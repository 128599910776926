export default theme => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: theme.drawer.expandedWidth,
        boxShadow: theme.elevation[2],
        height: '100%',
        backgroundColor: theme.drawer.background,
        overflowX: 'hidden',
        overflowY: 'auto',
        userSelect: 'none',
        textDecoration: 'none',
        color: '#a6b2c1'
    },

    inner: {
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'absolute',
        height: '100%',
        width: '100%',
        '&::-webkit-scrollbar': {
            width: 12,
            height: 18
        },
        '&::-webkit-scrollbar-thumb': {
            border: '4px solid rgba(0, 0, 0, 0)',
            height: 6,
            borderRadius: 10,
            backgroundClip: 'padding-box',
            backgroundColor: '#cccccc',
            boxShadow: 'inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)',
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0,
            display: 'none'
        },
        '&::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent'
        }
    },
})