import React, { useContext } from 'react'
import { context } from '../../components/Navbar/context'

import { useStyles } from '..'
import styles from './styles'
import clsx from 'clsx'


export const Wrapper = ({ children, className }) => {
    const classes = useStyles(styles)
    const drawer = useContext(context)
    const { expanded, variant } = drawer

    const rootStyle = clsx({
        [classes.permanent]: variant === 'permanent',
        [classes.expanded]: expanded && variant === 'permanent',
        [classes.temporary]: variant === 'temporary'
    })

    return (
        <div className={`${rootStyle} ${className}`}>
            {children}
        </div>
    )
}

Wrapper.defaultProps = {
    className: ''
}