import React from 'react'

import { useStyles } from '../../styles'
import styles from './styles'

import { Spinner } from '../../core'


export const Preloader = ({ status }) => {
    const classes = useStyles(styles)
    return (
        <div className={classes.root}>
            <Spinner />
            <div className={classes.status}>
                {status}
            </div>
        </div>
    )
}

Preloader.defaultProps = {
    status : ''
}

export default Preloader