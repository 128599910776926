import { UserTypes as types, AuthTypes } from "../types";
import { updateCurrentUser } from "./Auth";
import { setSnackbar } from "./Snackbar";
import { path } from "../../utils/api";
import axios from "axios";

export const fetchUsers = () => {
  return (dispatch, getState) => {
    const { selectedCompanies, token } = getState().auth;
    const endpoint = `${path}/usuario/get`;
    const headers = { headers: { Authorization: "Bearer " + token } };
    const data = { empresasSelecionadas: selectedCompanies.map((x) => x.id) };
    axios
      .post(endpoint, data, headers)
      .then((res) => dispatch(storeUsers(res.data)))
      .catch((err) => console.error(err));
  };
};

export const listUsers = (payload, exporter, token) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/usuario/getlist`;
    let headers = exporter
      ? {
          headers: { Authorization: "bearer " + token },
          responseType: "arraybuffer",
        }
      : { headers: { Authorization: "bearer " + token } };
    axios
      .post(endpoint, payload, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const listCompaniesUsers = (payload, token) => {
  return new Promise((resolve, reject) => {
    const endpoint = `${path}/empresa/get`;
    let headers = { headers: { Authorization: "bearer " + token } };
    axios
      .post(endpoint, payload, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const addUser = (payload, token) => {
  return new Promise((resolve, reject) => {
    const endpoint = path + "/usuario";
    const headers = { headers: { Authorization: "Bearer " + token } };
    axios
      .post(endpoint, payload, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateUser = (payload, token) => {
  return new Promise((resolve, reject) => {
    const endpoint = path + "/usuario";
    const headers = { headers: { Authorization: "Bearer " + token } };
    axios
      .put(endpoint, payload, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const enableUser = (payload, token) => {
  return new Promise((resolve, reject) => {
    const endpoint = path + "/usuario/status";
    const headers = { headers: { Authorization: "Bearer " + token } };
    axios
      .put(endpoint, payload, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const deleteUser = (id, token) => {
  return new Promise((resolve, reject) => {
    const endpoint = path + "/usuario/" + id;
    const headers = { headers: { Authorization: "Bearer " + token } };
    axios
      .delete(endpoint, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// export const deleteUser = (id) => {
//   return (dispatch, getState) => {
//     const token = getState().auth.token;

//     const endpoint = path + "/usuario/" + id;
//     const headers = { headers: { Authorization: "Bearer " + token } };
//     axios
//       .delete(endpoint, headers)
//       .then((res) => {
//         dispatch(
//           setSnackbar({
//             open: true,
//             message: res.data.msg,
//             duration: 4000,
//             variant: "success",
//           })
//         );
//         dispatch(fetchUsers());
//       })
//       .catch((err) => {
//         dispatch(
//           setSnackbar({
//             open: true,
//             message: err.response.data.msg,
//             duration: 4000,
//             variant: "error",
//           })
//         );
//       });
//   };
// };

const storeUsers = (data) => ({
  type: types.FETCH_USERS,
  data,
});

//--------------------------------  USER VEHICLES ---------------------------------------------------

export const fetchUserVehicles = (id) => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    const { token, selectedCompanies } = auth;
    const payload = {
      empresasSelecionadas: selectedCompanies.map((x) => x.id),
    };
    const endpoint = `${path}/usuarioveiculo/${id}`;
    const headers = { headers: { Authorization: "Bearer " + token } };
    axios
      .post(endpoint, payload, headers)
      .then((res) => dispatch(storeUserVehicles(res.data)))
      .catch((err) =>
        dispatch(
          setSnackbar({
            open: true,
            message: err.response.data.msg,
            duration: 4000,
            variant: "error",
          })
        )
      );
  };
};

export const addPermission = (data) => {
  return (dispatch, getState) => {
    const endpoint = path + "/usuarioveiculo";
    const headers = {
      headers: { Authorization: "Bearer " + getState().auth.token },
    };
    axios
      .post(endpoint, data, headers)
      .then((res) => {
        dispatch(
          setSnackbar({
            open: true,
            message: res.data.msg,
            duration: 4000,
            variant: "success",
          })
        );
        dispatch(fetchUserVehicles(data.usuario));
      })
      .catch((err) =>
        dispatch(
          setSnackbar({
            open: true,
            message: err.response.data.msg,
            duration: 4000,
            variant: "error",
          })
        )
      );
  };
};

export const removePermission = (data) => {
  return (dispatch, getState) => {
    const endpoint =
      path + "/usuarioveiculo/" + data.usuario + "/" + data.veiculo;
    const headers = {
      headers: { Authorization: "Bearer " + getState().auth.token },
    };
    axios
      .delete(endpoint, headers)
      .then((res) => {
        dispatch(
          setSnackbar({
            open: true,
            message: res.data.msg,
            duration: 4000,
            variant: "success",
          })
        );
        dispatch(fetchUserVehicles(data.usuario));
      })
      .catch((err) =>
        dispatch(
          setSnackbar({
            open: true,
            message: err.response.data.msg,
            duration: 4000,
            variant: "error",
          })
        )
      );
  };
};

const storeUserVehicles = (data) => ({
  type: types.FETCH_USERVEHICLES,
  data,
});

//--------------------------------  USER COMPANIES ---------------------------------------------------

export const fetchUserCompanies = (id) => {
  return (dispatch, getState) => {
    const auth = getState().auth;
    const { token } = auth;

    const endpoint = `${path}/usuarioempresa/${id}`;
    const headers = { headers: { Authorization: "Bearer " + token } };
    axios
      .get(endpoint, headers)
      .then((res) => dispatch(storeUserCompanies(res.data)))
      .catch((err) =>
        dispatch(
          setSnackbar({
            open: true,
            message: err.response.data.msg,
            duration: 4000,
            variant: "error",
          })
        )
      );
  };
};

export const addPermissionCompany = (data) => {
  return (dispatch, getState) => {
    const endpoint = path + "/usuarioempresa";
    const headers = {
      headers: { Authorization: "Bearer " + getState().auth.token },
    };
    axios
      .post(endpoint, data, headers)
      .then((res) => {
        dispatch(
          setSnackbar({
            open: true,
            message: res.data.msg,
            duration: 4000,
            variant: "success",
          })
        );
        dispatch(fetchUserCompanies(data.usuario));
      })
      .catch((err) =>
        dispatch(
          setSnackbar({
            open: true,
            message: err.response.data.msg,
            duration: 4000,
            variant: "error",
          })
        )
      );
  };
};

export const removePermissionCompany = (data) => {
  return (dispatch, getState) => {
    const endpoint =
      path + "/usuarioempresa/" + data.usuario + "/" + data.empresa;
    const headers = {
      headers: { Authorization: "Bearer " + getState().auth.token },
    };
    axios
      .delete(endpoint, headers)
      .then((res) => {
        dispatch(
          setSnackbar({
            open: true,
            message: res.data.msg,
            duration: 4000,
            variant: "success",
          })
        );
        dispatch(fetchUserCompanies(data.usuario));
      })
      .catch((err) =>
        dispatch(
          setSnackbar({
            open: true,
            message: err.response.data.msg,
            duration: 4000,
            variant: "error",
          })
        )
      );
  };
};

const storeUserCompanies = (data) => ({
  type: types.FETCH_USERCOMPANIES,
  data,
});

//-------------------------------- PERSONAL -------------------------------------------------------------

export const updatePersonal = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { token, id } = getState().auth;
      const endpoint = path + "/usuario/pessoal";
      const headers = {
        headers: {
          Authorization: "Bearer " + token,
          ContentType: "multipart/form-data",
        },
      };
      axios
        .put(endpoint, data, headers)
        .then((res) => {
          let newHeader = {
            headers: { Authorization: `bearer ${res.data.token}` },
          };

          //atualiza token na localStorage
          localStorage.setItem("token", res.data.token);

          //atualiza dados e token no redux
          dispatch({ type: AuthTypes.STORE_TOKEN, data: res.data });

          //atualiza token usado nas requisições
          dispatch(updateCurrentUser(newHeader, id));

          resolve(res.data.msg);
        })
        .catch((err) => {
          reject(err.response.data.msg);
        });
    });
  };
};

export const updatePersonalPass = (data) => {
  return (_, getState) => {
    return new Promise((resolve, reject) => {
      const token = getState().auth.token;

      const endpoint = path + "/usuario/pessoalsenha";
      const headers = { headers: { Authorization: "Bearer " + token } };
      axios
        .put(endpoint, data, headers)
        .then((res) => resolve(res.data.msg))
        .catch((err) => reject(err.response.data.msg));
    });
  };
};
