import { CompanyTypes as types } from '../types'

const initialState = {
    companies: [],
    states: [],
    cities: []
}

export const CompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_COMPANIES:
            return fetchCompanies(state, action)

        case types.FETCH_STATES:
            return fetchStates(state, action)

        case types.FETCH_CITIES:
            return fetchCities(state, action)

        default:
            return state
    }
}

const fetchCompanies = (state, action) => ({
    ...state,
    companies: action.data
})

const fetchStates = (state, action) => ({
    ...state,
    states: action.data
})

const fetchCities = (state, action) => ({
    ...state,
    cities: action.data
})