export default theme => ({
    root: {
        fontSize: 24,
        color: 'white',
        padding: 10,
        height: 30,
        width: 30,
        borderRadius: '50%',
        textAlign: 'center',
        backgroundColor: props => theme.palette[props.color].main,
        border: props => `2px solid ${theme.palette[props.color].dark}`,
        cursor: 'pointer'
    },


    disabled: {
        backgroundColor: '#aaaaaa',
        border: '1px solid #444444'
    }
})