export default theme => ({
    '@keyframes loader': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(360deg)'
        }
    },

    '@keyframes loadicons': {
        '0%': {
            transform: 'scale(0)'
        },

        '50%': {
            transform: 'scale(1)',
        },
        '100%': {
            transform: 'scale(0)',
        },
    },

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        padding: 7,
        backgroundColor: 'rgba(0,0,0,.3)',
        borderRadius: '50%'
    },

    icon: {
        animation: '$loadicons 1s infinite',
        color: 'white',
        fontSize: 25,
        margin: 5
    },
    loader: {
        fontSize: 15,
        position: 'absolute',
        borderTop: `3px solid rgba(0,0,0,.2)`,
        borderRight: `3px solid rgba(0,0,0,.2)`,
        borderBottom: `3px solid rgba(0,0,0,.2)`,
        borderLeft: '3px solid #ffffff',
        transform: 'translateZ(0)',
        animation: '$loader 1s infinite linear',
        borderRadius: '50%',
        width: 40,
        height: 40
    },

})

