import React, { useState, useRef } from 'react'

//Styles
import { makeStyles, Checkbox } from '@material-ui/core'
import styles from './jss/personal'
import classnames from 'classnames'

//i18n
import intl from 'react-intl-universal'

//Material UI components
import { DialogContent, DialogActions, Icon, Tooltip } from '@material-ui/core'

//Components
import LanguagePicker from '../../core/Pickers/LanguagePicker'
import FormFactory from '../../core/FormFactory/FormFactory'
import ChangePassForm from '../../utils/forms/NewPassword'

import locales from '../../locales/locales'
import { useEffect } from 'react'

const useStyles = makeStyles(styles)

const PersonalPanel = ({ onClose, user, forcarTrocar, dispatch, updatePersonal, updatePersonalPass, setSnackbar }) => {
    const classes = useStyles()
    const bottomRef = useRef(null)

    const suspect = `https://ui-avatars.com/api/?name=${user.name}&bold=true&background=ffc247&color=fff&size=128`

    const scrollToPass = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    //State
    const image = (user) ? ((user.image.length !== 0) ? user.image : suspect) : suspect
    const [newImage, setterPreview] = useState(image)
    const [file, setFile] = useState(null)
    const [, setExtension] = useState(null)
    const [language, setLanguage] = useState(user.language)
    const [inputs, setInputs] = useState(() => ChangePassForm(intl))
    const [enablePass, setEnablePass] = useState(Boolean(forcarTrocar))
    const [msgErro, setMsgErro] = useState(null)

    //rola a tela para a div com a checkbox de trocar senha
    useEffect(() => {
        scrollToPass()
    }, [enablePass])

    const onFileLoaded = e => {
        const file = e.target.files[0]
        if (!file) return null
        const extension = file.name.split('.')[1]

        if (extension !== 'jpg' && extension !== 'png')
            return setMsgErro(intl.get('personalprofile.invalid_format'))

        const size = file.size
        if (size > 1000000)
            return setMsgErro(intl.get('personalprofile.max_size'))

        setExtension(extension)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
            setterPreview(e.target.result)
            setFile(e.target.result)
        }
    }


    //Style
    const profileImage = classnames({
        [classes.profileImage]: true
    })

    const inputUpload = classnames({
        [classes.inputUpload]: true
    })

    const labelImage = classnames({
        [classes.labelImage]: true
    })

    const icon = classnames({
        [classes.icon]: true
    })

    const onSubmit = () => {
        const data = {
            imagem: file,
            idioma: language,
            id: user.id,
            senha: inputs.senha.value,
            novaSenha: inputs.novaSenha.value,
            alterarSenha: enablePass
        }
        const promises = [dispatch(updatePersonal(data))]
        Promise.all(promises)
            .then(res => {
                dispatch(setSnackbar({ open: true, message: res[0], duration: 4000, variant: 'success' }))

                const warningHandler = (message, detail) => null

                intl.init({
                    currentLocale: language,
                    locales,
                    warningHandler
                })

                onClose()
            })
            .catch(err => setMsgErro(err))
    }

    const onSelect = id => setLanguage(id)

    const isDisabled = () => {
        if (!enablePass) return ((language === user.language) && (!file))
        const { novaSenha, confirmacaoSenha } = inputs

        return ((!novaSenha.valid || !confirmacaoSenha.valid) || (novaSenha.value === '' || confirmacaoSenha.value === ''))
    }

    const disabled = isDisabled()

    const buttonStyle = classnames({
        [classes.button]: true,
        [classes.disabled]: disabled
    })

    return (
        <>
            <DialogContent>
                <div className={inputUpload}>
                    <label htmlFor='input-file'>
                        <img
                            alt={intl.get('personalprofile.alt_image')}
                            src={newImage}
                            name='image'
                            className={profileImage}
                        />
                        <Tooltip title={intl.get('personalprofile.update_image')} placement={'right-end'}>
                            <label htmlFor='input-file' className={labelImage}><Icon className={icon}>edit</Icon></label>
                        </Tooltip>
                    </label>
                    <input
                        type='file'
                        id='input-file'
                        name='input-file'
                        onChange={onFileLoaded}
                        accept='image/png, image/jpeg'
                        className={classes.hidden}
                    />
                </div>
                <div className={classes.informations}>
                    <label className={classes.label} htmlFor={'myInformations'}>
                        {intl.get('personalprofile.my_info')}
                    </label>
                    <div className={classes.informationsContainer} id={'myInformations'}>
                        <h3 className={classes.info}>{intl.get('personalprofile.company')}<span>{user.company}</span></h3>
                        <h3 className={classes.info}>{intl.get('personalprofile.profile_info')}<span>{user.profile}</span></h3>
                        <h3 className={classes.info}>{intl.get('personalprofile.name')}<span>{user.name}</span></h3>
                        <h3 className={classes.info}>{intl.get('personalprofile.email')}<span>{user.email}</span></h3>
                    </div>
                </div>

                <div className={classes.informations}>
                    <label className={(enablePass) ? classes.labelEditPass : classes.labelEdit} htmlFor={'editInformations'}>
                        {intl.get('personalprofile.edit_info')}
                    </label>
                    <div className={classes.formContainer} id={'editInformations'}>
                        <LanguagePicker onSelect={onSelect} />

                        {
                            enablePass && <FormFactory describer={inputs} setter={setInputs} />
                        }
                        <div className={classes.checkContainer} ref={bottomRef} >
                            <Checkbox checked={enablePass} onChange={e => (forcarTrocar) ? null : setEnablePass(e.target.checked)} id='password-checkbox' color={'default'} />
                            <label className={classes.checkLabel} htmlFor='password-checkbox'>{intl.get('personalprofile.update_pass')}</label>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className={classes.containerActions}>
                    {(Boolean(msgErro)) &&
                        <>
                            <div className={classes.msgErro}>
                                <p>{msgErro}</p>
                            </div>
                        </>
                    }
                    <button disabled={disabled} onClick={onSubmit} className={buttonStyle}>{intl.get('personalprofile.save')}</button>
                </div>
            </DialogActions>
        </>
    )
}

export default PersonalPanel