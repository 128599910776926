import React, { useRef, useState, useEffect } from "react";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import { uniqueId } from "../../helpers";
import { CustomTitle, CustomInput, ClickAwayListener, CustomOptions } from "..";

import styles from "./jss/CustomNumberInput";

const FunctionComponent = (props) => {
  const {
    title = "",
    value = NaN,
    setValue = () => NaN,
    items = null,
    setItems = null,
    unit = "",
    min = Number.NEGATIVE_INFINITY,
    max = Number.POSITIVE_INFINITY,
    decimalPlaces = 0,
    loading = false,
  } = props;
  const point = intl.get("global.point");
  const decimalSeparator = intl.get("global.decimalSeparator");
  const regex =
    decimalPlaces === 0
      ? /^-{0,1}\d+$/
      : new RegExp(
          `^-{0,1}\\d+\\${decimalSeparator}{0,1}\\d{0,${decimalPlaces}}$`
        );

  const idRef = useRef(uniqueId("NumberInput-"));
  const [showOptions, setShowOptions] = useState(() => false);
  const [input, setInput] = useState(() => "");
  const [error, setError] = useState(() => "");
  const classes = useStyles(styles, props);

  const validate = (input) => {
    if (input === "") {
      setError(
        decimalPlaces === 0
          ? intl.get(`core.CustomNumberInput.integerError`)
          : intl
              .get(`core.CustomNumberInput.floatError`)
              .replace("{decimalPlaces}", decimalPlaces)
      );
      setInput(input);
      return;
    }

    if (!regex.test(input)) {
      return;
    }

    setInput(input);

    const value = Number(input.replace(decimalSeparator, point));

    if (value < min) {
      setError(
        intl
          .get(`core.CustomNumberInput.minError`)
          .replace(
            "{min}",
            min.toFixed(decimalPlaces).replace(point, decimalSeparator)
          )
          .replace("{unit}", unit ? unit : "")
      );
      setValue(NaN);
      return;
    }

    if (value > max) {
      setError(
        intl
          .get(`core.CustomNumberInput.maxError`)
          .replace(
            "{max}",
            max.toFixed(decimalPlaces).replace(point, decimalSeparator)
          )
          .replace("{unit}", unit ? unit : "")
      );
      setValue(NaN);
      return;
    }

    setError("");
    setValue(value);
  };

  const openOptions = () => setShowOptions(true);

  const toggleOptions = () => setShowOptions((showOptions) => !showOptions);

  const handleChange = (input) => {
    if (!loading) validate(input);
  };

  const handleClick = ({ label }) => {
    validate(label);
  };

  useEffect(() => {
    if (
      !isNaN(value) &&
      (value === 0 || value !== Number(input.replace(decimalSeparator, point)))
    )
      validate(value.toString().replace(point, decimalSeparator));
  }, [value]);

  return (
    <div className={classes.root}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.customTitleContainer,
          }}
          title={title}
          message={error}
          messageType={error ? "error" : ""}
        />
      )}
      <CustomInput
        id={idRef.current}
        input={input}
        setInput={handleChange}
        placeholder={intl
          .get(`core.CustomNumberInput.placeholder`)
          .replace(
            "{min}",
            min.toFixed(decimalPlaces).replace(point, decimalSeparator)
          )
          .replace(
            "{max}",
            max.toFixed(decimalPlaces).replace(point, decimalSeparator)
          )
          .replaceAll("{unit}", unit ? unit : "")}
        valid={!Boolean(error)}
        focused={showOptions}
        showOptions={showOptions && items && setItems}
        disabled={value === min && value === max}
        handleClick={openOptions}
        mask
      />
      {showOptions && (
        <ClickAwayListener ignore={idRef.current} onClickAway={toggleOptions}>
          {items && setItems && (
            <CustomOptions
              items={items}
              handleClick={handleClick}
              error={Boolean(error)}
              loading={loading}
            />
          )}
        </ClickAwayListener>
      )}
    </div>
  );
};

export const CustomNumberInput = React.memo(FunctionComponent);

export default CustomNumberInput;
