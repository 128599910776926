//Does exactly what it sounds like. 
//Receive an array and a chunk's size and return a matrix of n arrays with $size length
// eg: chunker( [1,2,3,4,5], 2)  will return: [[1,2],[3,4],[5]] 
export const chunker = (arr, size) => {
    const chunks = []

    for (let index = 0; index < arr.length; index += size) {
        const chunk = arr.slice(index, index + size);
        chunks.push(chunk)
    }

    return chunks
}

export const compareIntArrays = (arr1, arr2) => {
    if (typeof arr1[0] === 'object') arr1 = arr1.map(x => x.id)
    if (typeof arr2[0] === 'object') arr2 = arr2.map(x => x.id)

    return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort())
}

export const mergeCards = (oldCards, cards) => {
    const result = [...oldCards]
    for (let card of cards) {
        const index = result.findIndex(x => x.title === card.title)
        result.splice(index, 1)
        result.push(card)
    }
    return result
}