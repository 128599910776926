import React, { useRef } from 'react'

import { useStyles, Ripple } from '..'

import styles from './jss/raised'
import clsx from 'clsx'
import { isPresetColor } from '../../utils/helpers'

export const Raised = ({
    children,
    className,
    style,
    color,
    rippleColor,
    disabled,
    flat,
    ...buttonProps
}) => {

    const interpolation = { color }
    const classes = useStyles(styles, interpolation)
    const self = useRef(null)

    const rootStyle = clsx({
        [classes.root]: true,
        [classes[color]]: isPresetColor(color) && !disabled,
        [classes.flat]: flat,
        [classes.disabled]: disabled,
    })

    const buttonStyle = `${rootStyle} ${className}`

    return (
        <button className={buttonStyle} style={style} ref={self} {...buttonProps}>
            <Ripple
                container={self}
                color={rippleColor}
            />
            {children}
        </button>
    )
}

Raised.defaultProps = {
    className: '',
    style: {},
    color: 'primary',
    rippleColor: 'white',
    disabled: false,
    flat: false
}


