import React from "react";
import { useSelector } from "react-redux";

//Styles
import { makeStyles } from "@material-ui/core";
import styles from "./jss/loginReset";

//Material UI components
import { Dialog, Button } from "@material-ui/core";
import { InputLogin, Snackbar } from "../../core";

const useStyles = makeStyles(styles);

const LoginForgotDialog = ({ open, openReset, state, onChange, onSubmit }) => {
  const classes = useStyles();

  const snackbar = useSelector((s) => s.snackbar.snackbar);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  };

  return (
    <Dialog open={open} onClose={() => openReset(false)}>
      <div className={classes.root}>
        <span>Recuperação de senha</span>
        <div className={classes.paper}>
          <div className={classes.header}>
            <div>Informe abaixo seu e-mail e sua nova senha</div>
          </div>
          <div className={classes.form}>
            <InputLogin
              type="email"
              value={state.email}
              onChange={onChange}
              name="email"
              className={classes.input}
              label="Email"
              autoFocus
            />
            <InputLogin
              type="password"
              value={state.senha}
              onChange={onChange}
              name="senha"
              className={classes.input}
              label="Nova senha"
            />
            <InputLogin
              type="password"
              value={state.confirmacao}
              onChange={onChange}
              name="confirmacao"
              className={classes.input}
              label="Confirmar senha"
              onKeyDown={onKeyDown}
            />
          </div>
          <div className={classes.footer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onSubmit}
            >
              Continuar
            </Button>
            <Button
              color="primary"
              className={classes.button}
              onClick={() => openReset(false)}
            >
              Voltar
            </Button>
          </div>
          <Snackbar {...snackbar} />
        </div>
      </div>
    </Dialog>
  );
};

export default LoginForgotDialog;
