const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-around",
    boxSizing: "border-box",
    minHeight:
      theme.dimensions.customHeight.small -
      2 * theme.dimensions.customBorder.medium,
    padding: theme.dimensions.customPadding.medium,
    background: theme.palette.grey[100],
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.primary,
  },
  container: {
    fontSize: "0.95em",
    overflow: "hidden",
  },
  label: {},
  dashes: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-around",
    boxSizing: "border-box",
    margin: `0 ${theme.dimensions.customMargin.small}px`,
    borderBottomStyle: "dashed",
    borderBottomColor: theme.palette.text.primary,
    borderBottomWidth: theme.dimensions.customBorder.small,
  },
});

export default styles;
