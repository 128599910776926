const STORE_TOKEN = "STORE_TOKEN";
const STORE_MENU = "STORE_MENU";
const STORE_COMPANIES = "STORE_COMPANIES";
const STORE_PERMISSIONS = "STORE_PERMISSIONS";
const SELECT_COMPANY = "SELECT_COMPANY";

export const AuthTypes = {
  STORE_TOKEN,
  STORE_MENU,
  STORE_COMPANIES,
  STORE_PERMISSIONS,
  SELECT_COMPANY,
};

//------------------------------------------------
const STORE_FLEET = "STORE_FLEET";
const CLEAR_TIMER = "CLEAR_TIMER";

export const FleetTypes = {
  STORE_FLEET,
  CLEAR_TIMER,
};

//------------------------------------------------
const STORE_USER = "STORE_USER";
const FETCH_USERS = "FETCH_USERS";
const FETCH_USERVEHICLES = "FETCH_USERVEHICLES";
const FETCH_USERCOMPANIES = "FETCH_USERCOMPANIES";

export const UserTypes = {
  STORE_USER,
  FETCH_USERS,
  FETCH_USERVEHICLES,
  FETCH_USERCOMPANIES,
};

//------------------------------------------------
const STORE_TRACKER = "STORE_TRACKER";
const STORE_FENCES = "STORE_FENCES";

export const TrackerTypes = {
  STORE_TRACKER,
  STORE_FENCES,
};

//------------------------------------------------
const FETCH_COMPANIES = "FETCH_COMPANIES";
const FETCH_STATES = "FETCH_STATES";
const FETCH_CITIES = "FETCH_CITIES";

export const CompanyTypes = {
  FETCH_COMPANIES,
  FETCH_STATES,
  FETCH_CITIES,
};

//------------------------------------------------
const FETCH_MANUTENCOES = "FETCH_MANUTENCOES";
export const ManutencaoTypes = {
  FETCH_MANUTENCOES,
};

//------------------------------------------------
const FETCH_REVIEW = "FETCH_REVIEW";
const LOADING_REVIEW = "LOADING_REVIEW";

export const ReviewTypes = {
  FETCH_REVIEW,
  LOADING_REVIEW,
};

//------------------------------------------------
const FETCH_PROFILES = "FETCH_PROFILES";

export const ProfileTypes = {
  FETCH_PROFILES,
};

//------------------------------------------------
const SET_SNACKBAR = "SET_SNACKBAR";

export const SnackBarTypes = {
  SET_SNACKBAR,
};

//------------------------------------------------
const SET_DIALOG = "SET_DIALOG";
const CLOSE_DIALOG = "CLOSE_DIALOG";

export const DialogTypes = {
  SET_DIALOG,
  CLOSE_DIALOG,
};

//------------------------------------------------
const FETCH_PROFILEPERMISSIONS = "FETCH_PROFILEPERMISSIONS";

export const ProfilePermissionsTypes = {
  FETCH_PROFILEPERMISSIONS,
};

//------------------------------------------------
const FETCH_VEHICLES = "FETCH_VEHICLES";
const FETCH_VEHICLES_DATA = "FETCH_VEHICLES_DATA";
const FETCH_VEHICLES_TELEMETRY = "FETCH_VEHICLES_TELEMETRY";
const FETCH_VEHICLE_CATEGORIES = "FETCH_VEHICLE_CATEGORIES";
const FETCH_VEHICLE_MAKERS = "FETCH_VEHICLE_MAKERS";
const FETCH_VEHICLE_MODELS = "FETCH_VEHICLE_MODELS";
const FETCH_VEHICLE_ACTIVITIES = "FETCH_VEHICLE_ACTIVITIES";
const FETCH_VEHICLE_GROUPINGS = "FETCH_VEHICLE_GROUPINGS";
const FETCH_VEHICLE_SHAFTS = "FETCH_VEHICLE_SHAFTS";
const FETCH_VEHICLE_BODIES = "FETCH_VEHICLE_BODIES";
const FETCH_VEHICLE_FENCES = "FETCH_VEHICLE_FENCES";
const FETCH_VEHICLES_REPORT = "FETCH_VEHICLES_REPORT";
const FETCH_VEHICLE_EVENTS = "FETCH_VEHICLE_EVENTS";
const START_LOAD_TELEMETRY = "START_LOAD_TELEMETRY";
const START_LOAD_REPORT = "START_LOAD_REPORT";
const START_LOAD_EVENT = "START_LOAD_EVENT";

export const VehicleTypes = {
  FETCH_VEHICLES,
  FETCH_VEHICLES_DATA,
  FETCH_VEHICLES_TELEMETRY,
  FETCH_VEHICLE_CATEGORIES,
  FETCH_VEHICLE_MAKERS,
  FETCH_VEHICLE_MODELS,
  FETCH_VEHICLE_ACTIVITIES,
  FETCH_VEHICLE_GROUPINGS,
  FETCH_VEHICLE_SHAFTS,
  FETCH_VEHICLE_BODIES,
  FETCH_VEHICLE_FENCES,
  FETCH_VEHICLES_REPORT,
  FETCH_VEHICLE_EVENTS,
  START_LOAD_TELEMETRY,
  START_LOAD_REPORT,
  START_LOAD_EVENT,
};

//------------------------------------------------
const FETCH_DRIVERS = "FETCH_DRIVERS";
const FETCH_DRIVER_VEHICLES = "FETCH_DRIVER_VEHICLES";
const FETCH_DRIVER_RFID = "FETCH_DRIVER_RFID";
const FETCH_DRIVER_IBUTTONS = "FETCH_DRIVER_IBUTTONS";
const FETCH_DRIVER_RFIDCARDS = "FETCH_DRIVER_RFIDCARDS";
const FETCH_DRIVER_SCHEDULES = "FETCH_DRIVER_SCHEDULES";
const FETCH_DRIVER_AWARDS = "FETCH_DRIVER_AWARDS";
const FETCH_DRIVER_STATISTICS = "FETCH_DRIVER_STATISTICS";
const FETCH_WORKLOAD = "FETCH_WORKLOAD";

export const DriverTypes = {
  FETCH_DRIVERS,
  FETCH_DRIVER_VEHICLES,
  FETCH_DRIVER_RFID,
  FETCH_DRIVER_IBUTTONS,
  FETCH_DRIVER_RFIDCARDS,
  FETCH_DRIVER_SCHEDULES,
  FETCH_DRIVER_AWARDS,
  FETCH_DRIVER_STATISTICS,
  FETCH_WORKLOAD,
};

//-------------------------------------------------------------------
const FETCH_DTCS = "FETCH_DTCS";
const LOADING_DTC = "LOADING_DTC";

export const DtcTypes = {
  FETCH_DTCS,
  LOADING_DTC,
};

//-------------------------------------------------------------------
const FETCH_MACROS = "FETCH_MACROS";

export const MacroTypes = {
  FETCH_MACROS,
};

//-------------------------------------------------------------------
const FETCH_TRAVELS = "FETCH_TRAVELS";

export const TravelTypes = {
  FETCH_TRAVELS,
};

//-------------------------------------------------------------------
const STORE_SEARCHBAR = "STORE_SEARCHBAR";
const STORE_FILTERS = "STORE_FILTERS";
const STORE_CUSTOM_FILTERS = "STORE_CUSTOM_FILTERS";
const STORE_LAST_FILTERS = "STORE_LAST_FILTERS";
const STORE_LAST_PREFERENCE = "STORE_LAST_PREFERENCE";
const REFRESH_SEARCHBAR = "REFRESH_SEARCHBAR";
const SET_REFRESH = "SET_REFRESH";

export const SearchbarTypes = {
  STORE_SEARCHBAR,
  STORE_FILTERS,
  STORE_CUSTOM_FILTERS,
  STORE_LAST_FILTERS,
  STORE_LAST_PREFERENCE,
  REFRESH_SEARCHBAR,
  SET_REFRESH,
};

//-------------------------------------------------------------------
const FETCH_TRACK_HISTORY_REPORT = "FETCH_TRACK_HISTORY_REPORT";

const TrackerHistoryReportTypes = {
  FETCH_TRACK_HISTORY_REPORT,
};

//-------------------------------------------------------------------
const STORE_CARDS = "STORE_CARDS";
const STORE_PREFERENCES = "STORE_PREFERENCES";
const SELECT_PREFERENCE = "SELECT_PREFERENCE";
const STORE_ADITIONAL = "STORE_ADITIONAL";
const INITIAL_FETCH = "INITIAL_FETCH";
const IS_LOADING = "IS_LOADING";

export const CardTypes = {
  STORE_CARDS,
  STORE_PREFERENCES,
  SELECT_PREFERENCE,
  STORE_ADITIONAL,
  INITIAL_FETCH,
  IS_LOADING,
};
//-------------------------------------------------------------------
