export default theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '10%'
    },

    icon: {
        color: theme.palette.default.dark,
        fontSize: 100
    },

    title: {
        color: theme.palette.default.dark,
        fontSize: 60,
        fontFamily: 'Montserrat,sans-serif',
        fontWeight: 300,
        lineHeight: 1.5,
        '@media (max-width: 900px)': {
            fontSize: 34
        }
    },

    redirect: {
        cursor: 'pointer',
        color: theme.palette.primary.light,
        '&:hover': {
            color: theme.palette.primary.dark
        }
    }
})