import React, { useRef, useEffect } from "react";
import { uniqueId } from "../../helpers";

const FunctionComponent = ({
  children,
  ignoreRefs = [],
  onClose = () => {},
}) => {
  const containerRef = useRef(null);
  const idRef = useRef(uniqueId("CustomCloseListener-"));

  useEffect(() => {
    const resizeListener = () => {
      onClose();
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [onClose]);

  useEffect(() => {
    const clickListener = ({ target }) => {
      if (
        containerRef.current.contains(target) ||
        ignoreRefs.some(({ current }) => current.contains(target))
      )
        return;

      onClose();
    };
    document.addEventListener("click", clickListener);

    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [onClose, ignoreRefs]);

  return (
    <span ref={containerRef} id={idRef.current}>
      {children}
    </span>
  );
};

export const CustomCloseListener = FunctionComponent;

export default CustomCloseListener;
