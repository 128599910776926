export default theme => ({
    root: {
        cursor: 'pointer',
        position: 'relative',
        width: '100%',
    },

    input: {
        height: 35,
        border: '1px solid #d3d3d3',
        borderRadius: '.3rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    grow: {
        flexGrow: 1
    },

    icon: {
        padding: 5,
        color: '#495057'
    },

    paper: {
        position: 'absolute',
        color: '#495057',
        width: 'calc( 98% - 4px)',
        top: '98%',
        minWidth: 150,
        backgroundColor: 'white',
        zIndex: 9999,
        borderRadius: '.2rem',
        boxShadow: theme.cardShadow,
        border: '1px solid #c6cbcf',
        padding: 5,
        marginBottom: 5,
        transition: 'transform 180ms ease',
        transformOrigin: 'top center'

    },

    open: {
        transform: 'scaleX(1,1)',
    },

    closed: {
        transform: 'scale(1,0)',
    },

    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 15,
        fontWeight : 400,
        color: '#495057'
    },

    flag: {
        width: 40,
        height: 20,
        padding: 5
    }
})