import React from 'react'

import { useStyles } from '..'
import styles from './jss/status'
import clsx from 'clsx'


export const Status = ({ ignicao }) => {
    const { descricao, color } = ignicao
    const interpolation = { color }
    const classes = useStyles(styles, interpolation)


    const statusStyle = clsx({
        [classes.root]: true
    })


    return (
        <div className={statusStyle}>
            {descricao}
        </div>
    )
}

export default Status