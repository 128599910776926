//Main validation function, it receives a string containing the value to be validated and a rule to compare with
const validate = (value, rules = {}, equalsToValue = null) => {
    let valid = true

    for (let key in rules) {
        switch (key) {

            case 'minLength':
                valid = valid && minLengthValidator(value, rules[key])
                break

            case 'maxLength':
                valid = valid && maxLengthValidator(value, rules[key])
                break

            case 'masked':
                valid = valid && isMaskedFilledValidator(value)
                break

            case 'isNumber':
                valid = valid && isNumberValidator(value)
                break
            
            case 'isEmail':
                valid = valid && isEmailValidator(value)
                break

            case 'equalsTo' : 
                valid = valid && value === equalsToValue
                break
            default: break
        }
    }

    return valid
}

//Validators! The actual logic lies here
const minLengthValidator = (value, rule) => (value.length >= rule)

const maxLengthValidator = (value, rule) => (value.length <= rule)

const isMaskedFilledValidator = value => (value.search('_') < 0)

const isNumberValidator = value => !isNaN(parseFloat(value)) && isFinite(value)

const isEmailValidator = value =>{
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    return regex.test(value.toLowerCase())
}


export default validate