export default theme => ({
    overlay: {
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        overflowY: 'hidden !important',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        zIndex: theme.zIndex.overlay,
    },
    '@global': {
        body: {
            overflowY: 'hidden'
        }
    }
})