export default theme => ({
    root: {
        position: 'absolute',
        left: '80%',
        top: -3,
        minHeight: 8,
        minWidth: 8,
        borderRadius: '50%',
        transition: 'transform 180ms ease-out',
        transform: 'scale(0)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: props => theme.palette[props.color].main

    },

    value: {
        fontSize: 11,
        fontWeight: 600,
        color: 'white',
        width: 15,
        height: 15,
        textAlign: 'center'
    },

    animate: {
        transition: 'transform 180ms ease-in',
        transform: 'scale(1)',
    },

})