export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        padding: 5,
        paddingLeft: 10,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        color: theme.drawer.color,
        '&:hover': {
            color: theme.drawer.colorHover
        },
    },

    active: {
        color: theme.drawer.colorHover,
    },


    label: {
        fontSize: 14,
        margin: 5,
        marginLeft: 15,
        flex: .8,
        color: theme.drawer.color,

    },

    icon: {
        fontSize: 18,
        padding: 5,
        minWidth: 18,
        minHeight: 18,
    },

    iconActive: {
        borderRadius: '50%',
        backgroundColor: theme.palette.warning.main,
        color: theme.drawer.background

    },
    wrapper: {
        textDecoration: 'none'
    }
})