import React, { useEffect, useState } from "react";
import { TextField, Tooltip } from "@material-ui/core";

import {
  cnpjMask,
  numberMask,
  phoneMask,
  postalCodeMask,
  plateMask,
} from "../../utils/masks";

import { CustomTitle } from "..";
import { FaQuestionCircle } from "react-icons/fa";
import { useStyles } from "../../hooks";
import clsx from "clsx";
import styles from "./jss/customTextField";

const FunctionComponent = (props) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const {
    title = "",
    errorMessage = "",
    small = "small",
    min = Number.NEGATIVE_INFINITY,
    max = Number.POSITIVE_INFINITY,
    defaultValue,
    mask,
    onChange = () => {},
    inputRef = null,
    autoFocus = false,
    help = false,
  } = props;
  const classes = useStyles(styles, props);
  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });

  useEffect(() => {
    if (defaultValue && typeof defaultValue === "string") {
      if (mask) {
        if (mask === "cnpj") {
          setValue(cnpjMask(defaultValue));
        }
        if (mask === "number") {
          setValue(defaultValue);
        }
        if (mask === "phone") {
          setValue(phoneMask(defaultValue));
        }
        if (mask === "postalCode") {
          setValue(postalCodeMask(defaultValue));
        }
        if (mask === "plateMask") {
          setValue(plateMask(defaultValue));
        }
      } else {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, mask]);

  const handleChange = (e) => {
    let inputValue = e.target.value;

    if (mask) {
      if (mask === "cnpj") {
        inputValue = cnpjMask(e.target.value);
      }
      if (mask === "number") {
        inputValue = numberMask(e.target.value);
      }
      if (mask === "phone") {
        inputValue = phoneMask(e.target.value);
      }
      if (mask === "postalCode") {
        inputValue = postalCodeMask(e.target.value);
      }
      if (mask === "plateMask") {
        inputValue = plateMask(e.target.value);
      }
    }
    onChange(inputValue);
    validInput(inputValue);
    setValue(inputValue);
  };

  const validInput = (value) => {
    if (value.length < min || value.length > max) {
      return setError(true);
    }
    return setError(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <CustomTitle
        title={title}
        classes={{
          container: classes.font,
        }}
      />

      <TextField
        inputRef={inputRef}
        error={error}
        focused={false}
        helperText={error ? errorMessage : ""}
        id="outlined-basic"
        size={small}
        autoFocus={autoFocus}
        variant="outlined"
        value={value}
        onChange={handleChange}
      />
      {help && (
        <Tooltip title={help} placement={"top-start"}>
          <span className={classes.help}>
            <FaQuestionCircle />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export const CustomTextField = React.memo(FunctionComponent);

export default CustomTextField;
