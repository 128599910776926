import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSetup } from './store/actions/Auth';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Preloader, DrawerProvider } from './core';
import { ToastContainer } from 'react-toastify';
import locales from './locales/locales';
import Catcher from './screens/Catcher';
import ReportsContextProvider from './context/Reports';

const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const Snackbar = lazy(() => import('./containers/Snackbar'));
const Dialog = lazy(() => import('./containers/Dialog'));
const NotFound = lazy(() => import('./screens/NotFound'));
const Error = lazy(() => import('./screens/Error'));
const CommissionRanking = lazy(() => import('./components/CupTruck/CommissionRanking'));
const CommissionRankingWithRule = lazy(() => import('./components/CupTruck/CommissionRankingWithRule'));
const CommissionHistory = lazy(() => import('./components/CupTruck/ComissionHistory'));
const Championship = lazy(() => import('./components/CupTruck/Championship'));
const EmissionApproval = lazy(() => import('./components/CupTruck/EmissionApproval'));
const EmissionTracker = lazy(() => import('./components/CupTruck/EmissionTracker'));
const EmissionHistory = lazy(() => import('./components/CupTruck/EmissionHistory'));
const EmissionLogs = lazy(() => import('./components/CupTruck/EmissionLogs'));
const EmissionTester = lazy(() => import('./components/CupTruck/EmissionTester'));
const TelematicsBox = lazy(() => import('./components/CupTruck/Cruds/TelematicsBox/TelematicsBox'));
const Lap = lazy(() => import('./components/CupTruck/Cruds/Lap/Lap'));

const App = () => {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);

  const auth = useSelector((s) => s.auth);
  const { menuPermissions, companies, language } = auth;

  const warningHandler = (message, detail) => null;

  intl.init({
    currentLocale: language,
    locales,
    warningHandler,
  });

  useEffect(() => {
    dispatch(fetchSetup());
  }, [dispatch]);

  useEffect(() => {
    if (menuPermissions.length && companies.length) setLoaded(true);
  }, [menuPermissions, companies]);

  const routerProps = {
    initialEntries: ['/'],
    initialIndex: 0,
  };

  return (
    <DrawerProvider>
      <ReportsContextProvider>
        {!loaded ? (
          <Catcher>
            <Preloader />
          </Catcher>
        ) : (
          <Catcher>
            <Routes router={routerProps} />
          </Catcher>
        )}
        <ToastContainer autoClose={3000} newestOnTop />
      </ReportsContextProvider>
    </DrawerProvider>
  );
};

const Routes = (props) => (
  <Suspense fallback={<div />}>
    <Router>
      <Route path="/" component={Navbar} />
      <Route path="/" component={Snackbar} />
      <Route path="/" component={Dialog} />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/campeonato" />} />
        <Route exact path="/error" component={Error} />
        <Route exact path="/campeonato" component={Championship} />
        <Route exact path="/campeonato/rastreador/emissao/:raceId" component={EmissionTracker} />
        <Route exact path="/campeonato/historico/emissao/:raceId" component={EmissionHistory} />
        <Route exact path="/campeonato/historico/emissao/:raceId/:pilotId" component={EmissionHistory} />
        <Route exact path="/logs" component={EmissionLogs} />
        <Route exact path="/campeonato/homologacao/sensor/:raceId" component={EmissionApproval} />
        <Route exact path="/campeonato/comissao/:id" component={CommissionRanking} />
        <Route exact path="/campeonato/comissao2/:id" component={CommissionRankingWithRule} />
        <Route exact path="/campeonato/comissao/historico/:id" component={CommissionHistory} />
        <Route exact path="/testador" component={EmissionTester} />
        <Route exact path="/configuracao/telematicsbox" component={TelematicsBox} />
        <Route exact path="/configuracao/voltas" component={Lap} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  </Suspense>
);

export default App;
