export default theme => ({
    root: {
        marginTop: 5,
        padding: 10,
        borderTop: '1px solid #cccccc',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    label: {
        fontSize: 11,
        fontWeight: 800,
        color: '#444444'
    },
    value: {
        fontSize: 13,
        fontWeight: 600,
        color: '#444444',
        whiteSpace: 'nowrap'
    }
})