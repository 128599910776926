export default (theme) => ({
  list: {
    padding: "10px",
    marginRight: 5,
  },
  listTitle: {
    display: "flex",
    alignItems: "center",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  title: {
    minWidth: "180px",
    maxWidth: "180px",
    "& > span": {
      fontWeight: 600,
    },
  },
  value: {
    minWidth: "180px",
    textAlign: "start",
  },
  button: {
    whiteSpace: "nowrap",
    marginRight: "10px",
    height: "40px",
    "@media(max-width: 960px)": {
      margin: "10px 0",
    },
  },
});
