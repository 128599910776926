import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { FaExclamation } from "react-icons/fa";
import { loginAttempt } from "../../store/actions/Auth";
import {
  forgotPassword,
  resetPassword,
} from "../../store/actions/ForgotResetPassword";
import { setSnackbar } from "../../store/actions/Snackbar";

import { useStyles } from "../../styles";
import styles from "./jss/login";

import { Snackbar } from "../../core";
import { isEmail } from "../../utils/helpers";

import FormComp from "./Form";
import LoginForgotDialog from "./LoginForgotDialog";
import LoginResetDialog from "./LoginResetDialog";

const logo = require("../../assets/images/logo.png");

export const Login = () => {
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const snackbar = useSelector((s) => s.snackbar.snackbar);
  const [loading, setLoading] = useState(false);
  const [forgot, openForgot] = useState(false);
  const [reset, openReset] = useState(false);
  const [main, setMain] = useState(null);
  const [token, setToken] = useState(null);
  useEffect(() => {
    const route = window.location.pathname.split("/");

    window.history.pushState("", "", "/");

    if (!reset) {
      setMain(route[1]);
      setToken(route[2]);
    }
  }, [reset]);

  if (main === "reset-password" && token && !reset) {
    openReset(true);
    setMain(null);

    // limpa a url
    window.history.pushState("", "TELEMATIC", "/");
  }

  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(loginAttempt(state)).catch((_) => {
      dispatch(
        setSnackbar({
          title: "Erro ao realizar login, verifique seus dados de acesso!",
          color: "danger",
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
      setLoading(false);
    });
  };

  const onSubmitForgot = () => {
    if (state.email === "" || !isEmail(state.email))
      return dispatch(
        setSnackbar({
          title: "Insira um e-mail válido!",
          color: "danger",
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );

    dispatch(forgotPassword(state));
    return openForgot(false);
  };

  const onSubmitReset = () => {
    const { email, senha, confirmacao } = state;

    if (
      !email ||
      !senha ||
      !confirmacao ||
      email === "" ||
      senha === "" ||
      confirmacao === ""
    ) {
      return dispatch(
        setSnackbar({
          title: "Certifique-se de preencher todos os campos para continuar!",
          color: "danger",
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
    }

    if (!isEmail(email)) {
      return dispatch(
        setSnackbar({
          title: "Insira um e-mail válido!",
          color: "danger",
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
    }

    if (senha.length < 6 || senha.length > 16) {
      return dispatch(
        setSnackbar({
          title: "Sua senha deve conter entre 6 e 16 caracteres!",
          color: "danger",
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
    }

    if (senha !== confirmacao) {
      return dispatch(
        setSnackbar({
          title: "As senhas inseridas não coincidem, verifique!",
          color: "danger",
          open: true,
          icon: FaExclamation,
          duration: 4000,
        })
      );
    }

    const data = { email, senha, token };

    dispatch(resetPassword(data));
    setMain(null);
    setToken(null);
    return openReset(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.backgound} />
      <div
        className={classes.paper}
        style={{ display: (forgot || reset) && "none" }}
      >
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt="logo" />
        </div>
        <div className={classes.formContainer}>
          <FormComp
            state={state}
            onChange={onChange}
            onSubmit={onSubmit}
            openForgot={openForgot}
            loading={loading}
          />

          <LoginForgotDialog
            open={forgot}
            openForgot={openForgot}
            state={state}
            onChange={onChange}
            onSubmit={onSubmitForgot}
          />
          <LoginResetDialog
            open={reset}
            openReset={openReset}
            state={state}
            onChange={onChange}
            onSubmit={onSubmitReset}
          />
          <Snackbar
            {...snackbar}
            onClose={() => dispatch(setSnackbar({ open: false }))}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
