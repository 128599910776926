export default theme => ({
    root: {
        padding: 10,
        paddingLeft: '20%',
        cursor: 'pointer',
        color: theme.drawer.color,
        '&:hover': {
            color: theme.drawer.colorHover
        }
    },

    active: {
        color: theme.drawer.colorHover
    },

    label: {
        fontSize: 14,
    },

    wrapper: {
        textDecoration: 'none'
    }
})