import React from 'react'

import { useStyles } from '../../styles'
import styles from './styles'

export const Paper = ({ children, elevation, style, className }) => {
    const classes = useStyles(styles, { elevation })

    const paperStyle = `${classes.root} ${className}`

    return (
        <div className={paperStyle} style={style}>
            {children}
        </div>
    )
}

Paper.defaultProps = {
    elevation: 1,
    style: {}
}

export default Paper