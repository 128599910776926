import React from "react";
import { useStyles } from "../../hooks";
import clsx from "clsx";

import styles from "./jss/CustomOptionsSection";

const FunctionComponent = (props) => {
  const { item = {} } = props;

  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });

  return (
    <div className={rootStyle}>
      <div className={classes.dashes} />
      <div className={classes.container}>
        <span className={classes.label}>{item.label}</span>
      </div>
      <div className={classes.dashes} />
    </div>
  );
};

export const CustomOptionsSection = React.memo(FunctionComponent);

export default CustomOptionsSection;
