/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useCallback } from "react";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import clsx from "clsx";
import { uniqueId } from "../../helpers";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { CustomTitle, CustomCloseListener, CustomOptions } from "..";

import styles from "./jss/CustomComboBox";

const FunctionComponent = (props) => {
  const {
    title = "",
    items = [],
    setItems = () => [],
    feminine = false,
    required = false,
    multiSelect = false,
    loading = false,
    valid = () => true,
  } = props;

  const comboBoxIdRef = useRef(uniqueId("ComboBox-"));
  const [showOptions, setShowOptions] = useState(() => false);
  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });
  const containerStyle = clsx({
    [classes.container]: true,
    [classes.focusedContainer]: showOptions,
  });

  const renderLabel = () => {
    const selectedItems = items.filter(({ active }) => active);
    if (selectedItems.length === 1 && selectedItems[0])
      return selectedItems[0].label;

    if (!selectedItems.length || !items.length)
      return feminine
        ? intl.get("core.CustomComboBox.noneSelectedFeminine")
        : intl.get("core.CustomComboBox.noneSelected");

    if (selectedItems.length === items.length)
      return feminine
        ? intl.get("core.CustomComboBox.allSelectedFeminine")
        : intl.get("core.CustomComboBox.allSelected");

    return feminine
      ? `${selectedItems.length} ${intl.get(
          "core.CustomComboBox.selectedFeminine"
        )}`
      : `${selectedItems.length} ${intl.get("core.CustomComboBox.selected")}`;
  };

  const toggleOptions = () => setShowOptions((showOptions) => !showOptions);

  const closeOptions = () => setShowOptions(false);

  const handleClick = useCallback(({ id, active }) => {
    setItems((items) =>
      items.map((item) => ({
        ...item,
        active: item.id === id && (required || !active),
      }))
    );

    valid(required);
  }, []);

  const handleClickMultiSelect = useCallback(({ id, active }) => {
    setItems((items) => {
      const selectedItems = items.filter(({ active }) => active);

      return items.map((item) => ({
        ...item,
        active:
          item.id === id
            ? (required && selectedItems.length === 1) || !active
            : item.active,
      }));
    });

    valid(required);
  }, []);

  const toggleAll = useCallback(({ active }) => {
    setItems((items) =>
      items.map((item, index) => ({
        ...item,
        active: required && !index && active ? true : !active,
      }))
    );

    valid(required);
  }, []);

  return (
    <div className={rootStyle}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.title,
          }}
          title={title}
        />
      )}
      <label htmlFor={comboBoxIdRef.current} className={containerStyle}>
        <div
          id={comboBoxIdRef.current}
          className={classes.labelContainer}
          onClick={toggleOptions}
        >
          {renderLabel()}
        </div>
        <div className={classes.caretContainer} onClick={toggleOptions}>
          {showOptions ? <FaCaretUp /> : <FaCaretDown />}
        </div>
      </label>
      {showOptions && (
        <CustomCloseListener onClose={closeOptions}>
          <CustomOptions
            items={items}
            handleClick={multiSelect ? handleClickMultiSelect : handleClick}
            toggleAll={multiSelect ? toggleAll : null}
            feminine={feminine}
            loading={loading}
          />
        </CustomCloseListener>
      )}
    </div>
  );
};

export const CustomComboBox = React.memo(FunctionComponent);

export default CustomComboBox;
