import React, { useState, useCallback } from "react";
import { format } from "date-fns";

import { theme } from "../../styles";

const map = (value, inputRange, outputRange) =>
  ((value - inputRange[0]) * (outputRange[1] - outputRange[0])) /
    (inputRange[1] - inputRange[0]) +
  outputRange[0];

const FunctionComponent = (props) => {
  const {
    top,
    left,
    diameterFactor,
    dateTime,
    batteryVoltage = null,
    emission = null,
    alert,
    ignitionOff = false,
    isPaused,
    closerToTop,
    closerToLeft,
    emissionRange,
    diameterRange,
    rgbRange,
    opacity,
    opacityHover,
  } = props;

  const diameter =
    (emission === null
      ? Math.min(diameterRange[0], diameterRange[1])
      : map(emission, emissionRange, diameterRange)) * diameterFactor;
  const radius = diameter / 2;
  const rgb =
    emission === null
      ? Math.max(rgbRange[0], rgbRange[1])
      : map(emission, emissionRange, rgbRange);
  const cardHeight = 100;
  const cardWidth = 155;

  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = useCallback(() => setHovered(true), []);
  const handleMouseLeave = useCallback(() => setHovered(false), []);

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: top,
          left: left,
          width: diameter,
          height: diameter,
          marginLeft: -radius,
          marginTop: -radius,
          borderRadius: radius,
          background: ignitionOff
            ? "#493d8f"
            : isPaused? '#154D75' : `rgb(${alert || emission === null ? 255 : rgb}, ${rgb}, ${
                emission === null ? 0 : rgb
              })`,
          opacity: opacity,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <div
        style={{
          position: "absolute",
          display: hovered ? "flex" : "none",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          zIndex: 10,
          top: top,
          left: left,
          height: cardHeight,
          width: cardWidth,
          boxSizing: "border-box",
          borderRadius: `${closerToTop && closerToLeft ? 2 : 8}px ${
            closerToTop && !closerToLeft ? 2 : 8
          }px ${!closerToTop && !closerToLeft ? 2 : 8}px ${
            !closerToTop && closerToLeft ? 2 : 8
          }px`,
          marginTop: closerToTop ? 0 : -cardHeight,
          marginLeft: closerToLeft ? 0 : -cardWidth,
          padding: 5,
          background: "rgb(49, 57, 71)",
          color: "white",
          fontFamily: theme.typography.standard.fontFamily,
          fontSize: "1em",
          fontWeight: "600",
          opacity: opacityHover,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div style={{ height: "100%" }}>
          <span>Hora: {format(dateTime, "HH:mm:ss")}</span>
          <div style={{ height: 6 }} />
          <span>Coeficiente de</span>
          <div style={{ height: 2 }} />
          <span>
            emissões: {emission !== null ? emission.toFixed(2) : "N/C"}
          </span>
          <div style={{ height: 6 }} />
          <span>
            Bateria:{" "}
            {batteryVoltage !== null ? `${batteryVoltage.toFixed(2)} V` : "N/D"}
          </span>
        </div>
      </div>
    </div>
  );
};

export const CustomEmissionMarker = FunctionComponent;

export default CustomEmissionMarker;
