import React, { useState } from 'react'

import { useStyles, Collapse } from '../../'
import styles from './jss/nestedParent'
import clsx from 'clsx'


import { icons } from './icons'
import { FaChevronLeft } from 'react-icons/fa'

import { NestedChild, ShallowChild } from '.'



export const NestedParent = ({ item, expanded, setExpanded, location, wrapper }) => {
    const classes = useStyles(styles)

    const [open, setOpen] = useState(false)

    const isActive = () => {
        const { pathname } = location
        const { path } = item
        return `/${pathname.split('/')[1]}` === path
    }

    const active = isActive()

    const rootStyle = clsx({
        [classes.root]: true,
        [classes.expanded]: expanded,
        [classes.active]: active
    })

    const iconStyle = clsx({
        [classes.icon]: true,
        [classes.iconActive]: active
    })

    const chevronStyle = clsx({
        [classes.chevron]: true,
        [classes.opened]: open
    })

    const renderChildren = () => {
        const { itens } = item
        const result = []
        itens.forEach(children => {
            const childProps = { children, expanded, location, wrapper }
            if (children.hasOwnProperty('itens') && children.itens.length)
                return result.push(<NestedChild {...childProps} key={children.apelido} />)

            return result.push(<ShallowChild {...childProps} key={children.apelido} setExpanded={setExpanded} />)
        }
        )
        return result
    }

    const { apelido, icon } = item
    const Icon = icons[icon]
    return (
        <>
            <div className={rootStyle} onClick={() => setOpen(!open)}>
                <Icon className={iconStyle} />
                <div className={classes.label}>
                    {apelido}
                </div>
                <FaChevronLeft className={chevronStyle} />
            </div>
            <div className={classes.collapse}>
                <Collapse in={open && expanded}>
                    {renderChildren()}
                </Collapse>
            </div>
        </>
    )
}
