export const styles = (theme) => ({
  root: {
    display: "block",
    width: theme.dimensions.customWidth.full,
    minHeight: theme.dimensions.customHeight.small,
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: 12,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
  },
  input: {
    display: "block",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.paper,
    width: theme.dimensions.customWidth.full,
    minHeight: theme.dimensions.customHeight.small,
    borderColor: theme.palette.text.secondary,
    borderStyle: "solid",
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: theme.dimensions.customBorderRadius.small,
    padding: theme.dimensions.customPadding.medium,
    paddingLeft:
      theme.dimensions.customPadding.medium +
      theme.dimensions.customBorder.medium -
      theme.dimensions.customBorder.small,
    fontFamily: "inherit",
    fontSize: 12,
    fontWeight: "inherit",
    letterSpacing: "inherit",
    color: "inherit",
    overflow: "hidden",
    outline: "none",
  },
  hover: {
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
  },
  focusedInput: {
    borderWidth: theme.dimensions.customBorder.medium,
    padding: theme.dimensions.customPadding.medium,
  },
  showOptionsInput: {
    borderRadius: `${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.none} ${theme.dimensions.customBorderRadius.none}`,
  },
  validInput: {
    borderColor: theme.palette.primary.dark,
  },
  errorInput: {
    borderColor: theme.palette.error.dark,
  },
  disabled: {
    userSelect: "none!important",
  },
});

export default styles;
