import { VehicleTypes as types } from '../types'

const initialState = {
    vehiclesData: [],
    vehiclesDataLastFetch: null,

    vehiclesTelemetry: [],
    telemetryLastFetch: null,
    telemetryLastFilter: null,
    telemetryLoading: false,

    vehiclesReport: [],
    reportLastFetch: null,
    reportLastFilter: null,
    reportLoading: false,

    events: [],
    eventsLastFetch: null,
    eventsLastFilter: null,
    eventsLoading: false,

    vehiclesFetchedAt: null,
    vehicles: [],
    routes: [],
    categories: [],
    makers: [],
    models: [],
    groupings: [],
    activities: [],
    shafts: [],
    bodies: [],

}

export const VehicleReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.FETCH_VEHICLES_DATA: return fetchVehiclesData(state, action)

        case types.FETCH_VEHICLES_TELEMETRY: return fetchVehiclesTelemetry(state, action)

        case types.FETCH_VEHICLES_REPORT: return fetchVehiclesReport(state, action)

        case types.FETCH_VEHICLES: return fetchVehicles(state, action)

        case types.FETCH_VEHICLE_CATEGORIES: return fetchCategories(state, action)

        case types.FETCH_VEHICLE_MAKERS: return fetchMakers(state, action)

        case types.FETCH_VEHICLE_MODELS: return fetchModels(state, action)

        case types.FETCH_VEHICLE_GROUPINGS: return fetchGroupings(state, action)
        
        case types.FETCH_VEHICLE_ACTIVITIES: return fetchActivities(state, action)

        case types.FETCH_VEHICLE_SHAFTS: return fetchShafts(state, action)

        case types.FETCH_VEHICLE_BODIES: return fetchBodies(state, action)

        case types.FETCH_VEHICLE_EVENTS: return fetchEvents(state, action)

        case types.START_LOAD_TELEMETRY: return { ...state, telemetryLoading: true }

        case types.START_LOAD_REPORT: return { ...state, reportLoading: true, vehiclesReport: [] }

        case types.START_LOAD_EVENT: return { ...state, eventsLoading: true }

        default: return state
    }
}

let fix = (process.env.REACT_APP_FIX_TIME) ? parseInt(process.env.REACT_APP_FIX_TIME) : 0

const fetchVehiclesData = (state, action) => ({
    ...state,
    vehiclesData: action.data,
    vehiclesDataLastFetch: Date.now() + fix
})

const fetchVehiclesTelemetry = (state, action) => ({
    ...state,
    vehiclesTelemetry: action.data,
    fetchedVehicles: true,
    telemetryLastFetch: Date.now() + fix,
    telemetryLastFilter: JSON.stringify(action.filter),
    telemetryLoading: false
})

const fetchVehiclesReport = (state, action) => ({
    ...state,
    vehiclesReport: action.data,
    fetchedVehicles: true,
    reportLastFetch: Date.now() + fix,
    reportLastFilter: JSON.stringify(action.filter),
    reportLoading: false,
})

const fetchVehicles = (state, action) => ({
    ...state,
    vehicles: action.data,
    fetchedVehicles: true,
    vehiclesFetchedAt: Date.now() + fix
})

const fetchCategories = (state, action) => ({
    ...state,
    categories: action.data
})

const fetchMakers = (state, action) => ({
    ...state,
    makers: action.data
})

const fetchModels = (state, action) => ({
    ...state,
    models: action.data
})

const fetchGroupings = (state, action) => ({
    ...state,
    groupings: action.data
})

const fetchActivities = (state, action) => ({
    ...state,
    activities: action.data
})

const fetchShafts = (state, action) => ({
    ...state,
    shafts: action.data
})

const fetchBodies = (state, action) => ({
    ...state,
    bodies: action.data
})

const fetchEvents = (state, action) => ({
    ...state,
    events: action.data,
    eventsLastFetch: Date.now() + fix,
    eventsLastFilter: JSON.stringify(action.filter),
    eventsLoading: false,
})