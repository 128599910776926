import React from 'react'

import { Raised } from './Raised'
import { Icon } from './Icon'
import { Basic } from './Basic'

export const Button = ({ variant, ...props }) => {
    const render = () => {
        switch (variant) {
            case 'basic': return <Basic {...props} />
            case 'raised': return <Raised {...props} />
            case 'icon': return <Icon {...props} />
            default: return null
        }
    }

    return render()
}

Button.defaultProps = {
    variant: 'raised'
}