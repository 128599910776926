import React from 'react'
import { context, theme } from './'

const { Provider } = context

export const ThemeProvider = ({ children }) => {

    return (
        <Provider value={theme}>
            {children}
        </Provider>
    )
}
