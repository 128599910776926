import { toRgba } from "../../../utils/helpers"

export default theme => ({
    root: {
        userSelect: 'none',
        minHeight: 36,
        minWidth: 90,
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        fontSize: 14,
        fontWeight: 500,
        cursor: 'pointer',
        transition: 'box-shadow 100ms ease',
        position: 'relative',
        overflow: 'hidden'
    },

    stroked: {
        border: `1px solid transparent`
    },

    primary: { color: theme.palette.primary.main, borderColor: theme.palette.primary.main },
    success: { color: theme.palette.success.main, borderColor: theme.palette.success.main },
    accent: { color: theme.palette.accent.main, borderColor: theme.palette.accent.main },
    warning: { color: theme.palette.warning.main, borderColor: theme.palette.warning.main },
    danger: { color: theme.palette.danger.main, borderColor: theme.palette.danger.main },

    disabled: {
        borderColor: toRgba(theme.palette.default.main, .5),
        color: theme.palette.default.dark,
        pointerEvents: 'none'
    }
})