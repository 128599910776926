export default (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.default.dark,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.standard.fontSizeXs,
    },
  },
  status: {
    marginTop: theme.dimensions.customMargin.xLarge,
    fontSize: "1.2em",
  },
});
