import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useStyles } from '..'
import styles from './jss/user'
import clsx from 'clsx'

//i18n
import intl from 'react-intl-universal'

import { FaChevronDown, FaUserAlt, FaSignOutAlt } from 'react-icons/fa'
import ClickAwayListener from '../ClickAwayListener/ClickAwayListener'

import Personal from '../../components/Personal/Personal'

export const User = () => {
    const classes = useStyles(styles)
    const dispatch = useDispatch()
    const user = useSelector(s => s.user)
    const { name, image } = user

    const forcarTrocar = useSelector(s => s.auth.trocarSenha)

    const [open, setOpen] = useState(false)
    const [openPersonal, togglerPersonal] = useState(false)

    //openPersonal colocado abaixo para caso o usuário aperte ESC reabra o popup
    useEffect(() => {
        if (forcarTrocar)
            togglerPersonal(true)
    }, [forcarTrocar, openPersonal])

    const getImage = () => {
        if (image) return image
        return `https://ui-avatars.com/api/?name=${name}&bold=true&background=ffc247&color=fff`
    }

    const logout = () => {
        dispatch({ type: 'LOGOUT' })
    }

    const openDialog = () => {
        setOpen(!open)
        togglerPersonal(true)
    }

    const onClose = () => {
        togglerPersonal(false)
    }

    const paperStyle = clsx({
        [classes.paper]: true,
        [classes.expanded]: open
    })

    const getName = () => {
        if(name){
            if ( name.length < 20) return name
        return `${name.split('').filter((_, i) => i < 16).join('')}...`}
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.root} onClick={() => setOpen(!open)}>
                <div className={classes.avatar}>
                    <img src={getImage()} alt='user' className={classes.image} />
                </div>
                <div className={classes.name}>
                    {getName()}
                </div>
                <FaChevronDown className={classes.chevron} />
            </div>
            <ClickAwayListener onClickAway={() => open ? setOpen(false) : null}>
                <div className={paperStyle}>
                    <div className={classes.item} onClick={openDialog}>
                        <FaUserAlt className={classes.itemIcon} />
                        <div className={classes.nameItem}>
                            {intl.get('navbar.user.profile')}
                        </div>
                    </div>
                    <div className={classes.item} onClick={logout}>
                        <FaSignOutAlt className={classes.itemIcon} />
                        <div className={classes.nameItem}>
                            {intl.get('navbar.user.logout')}
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
            {openPersonal && <Personal
                onClose={onClose}
                open={openPersonal}
                forcarTrocar={forcarTrocar}
                user={user}
            />}
        </div>
    )
}
export default User