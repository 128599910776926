import React, { useState } from 'react'

import { useStyles, Paper } from '..'
import { Tooltip as Indicator } from './Tooltip'
import styles from './jss/horizontal'
import { FaTruck, FaTractor, FaCar, FaWifi, FaDrawPolygon, FaTachometerAlt, FaBandAid, FaClock, FaGasPump, FaCloudscale, FaCarSide } from 'react-icons/fa'
import { Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { MobileDetails } from './MobileDetails'

const beltImage = require('../../assets/images/belt.png')
const batteryImage = require('../../assets/images/battery.png')
const hazardImage = require('../../assets/images/hazard.png')
const tempImage = require('../../assets/images/temperature.png')
const engineImage = require('../../assets/images/engine.png')

export const HorizontalVehicle = ({ vehicle, width }) => {
    const {
        descricao,
        ignicao,
        rssi,
        status_dtc_presente: falhas,
        temp_liquido_arref: temperatura,
        status_cinto: cinto,
        tensao_bateria: tensao,
        status_pisca_alerta: pisca,
        rotacao,
        acelerador,
        velocidade_atual: velocidade,
        nivel_combustivel: combustivel,
        odometro,
        data,
        cerca,
        status,
        id_categoria
    } = vehicle

    const interpolation = {
        status,
        off: ignicao.descricao === 'Desligado'
    }
    const classes = useStyles(styles, interpolation)

    const [mobileOpen, setMobileOpen] = useState(false)

    const toggleMobile = () => setMobileOpen(!mobileOpen)
    const closeMobile = () => setMobileOpen(false)

    const iconStyle = clsx({
        [classes.iconContainer]: true,
        [classes.disabled]: ignicao.descricao === 'Desligado'
    })


    const getCombAlert = () => {
        if (combustivel === 'N/A') return false
        const value = parseFloat(combustivel.replace('%', ''))
        if (value < 10) return { color: 'danger' }
        if (value < 25) return { color: 'warning' }
        return false
    }

    const comb = {
        alerta: getCombAlert(),
        descricao: `Nível Combustível : ${combustivel}`
    }

    const odometer = {
        alerta: false,
        descricao: `Odômetro : ${odometro}`
    }

    const speed = {
        alerts: false,
        descricao: `Velocidade: ${velocidade}`
    }

    const mobileStatus = {
        rssi, falhas, temperatura, cinto, tensao, cerca, pisca, rotacao, acelerador, comb, odometer, speed, data
    }

    const getTitle = title => {
        if (title.length < 16) return title

        return (
            <Tooltip title={title} placement='top'>
                <span>
                    {`${title.split('').filter((_, i) => i < 14).join('')}...`}
                </span>
            </Tooltip>
        )
    }

    return (
        <Paper className={classes.root}>
            <Tooltip title={ignicao.descricao} placement='top'>
                <div className={iconStyle}>
                    <Icon category={id_categoria} ignition={ignicao} className={classes.icon} />
                </div>
            </Tooltip>
            <div className={classes.title}>
                {getTitle(descricao)}
            </div>
            <div className={classes.indicators}>
                {
                    width > 511 ?
                        <>
                            <Indicator Icon={FaWifi} {...rssi} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator image={engineImage}{...falhas} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator image={tempImage} {...temperatura} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator image={beltImage} {...cinto} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator image={batteryImage}{...tensao} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator Icon={FaDrawPolygon} {...cerca} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator image={hazardImage}{...pisca} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator Icon={FaTachometerAlt} {...rotacao} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator Icon={FaBandAid} {...acelerador} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator Icon={FaGasPump} {...comb} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator Icon={FaCloudscale} {...odometer} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator Icon={FaCarSide} {...speed} className={classes.tooltip} badgeStyle={classes.badge} />
                            <Indicator Icon={FaClock} {...data} className={classes.tooltip} badgeStyle={classes.badge} isLast />
                        </> :
                        <div className={classes.mobile} onClick={toggleMobile}>
                            Ver Detalhes
                        </div>
                }
            </div>
            <MobileDetails
                open={mobileOpen}
                onClose={closeMobile}
                status={mobileStatus}
                title={descricao}
            />
        </Paper>
    )
}

const Icon = ({ category, ignition, ...props }) => {
    const render = () => {
        switch (category) {
            case 1: return <FaTruck {...props} />
            case 2: return <FaCar {...props} />
            default: return <FaTractor {...props} />
        }
    }
    return render()
}