import React from "react";

import {
  IconButton,
  Icon,
  Tooltip,
  Divider,
  ListItemText,
  List,
  ListItem,
  Paper,
} from "@material-ui/core";

import { useStyles } from "../../hooks";
import styles from "./jss/customList";

const FunctionComponent = ({
  title = false,
  button = false,
  submit = () => {},
  columns,
  data,
}) => {
  const classes = useStyles(styles);

  return (
    <Paper className={classes.list}>
      {title && (
        <div className={classes.listTitle}>
          {title}
          {button && (
            <Tooltip title={"Editar"} placement="right-end">
              <div className={classes.buttom}>
                <IconButton onClick={() => submit(data)}>
                  <Icon>edit</Icon>
                </IconButton>
              </div>
            </Tooltip>
          )}
        </div>
      )}
      <List component="nav" aria-label="mailbox folders">
        {columns.map(({ title, field }) => (
          <div key={title}>
            <ListItem className={classes.listItem}>
              <ListItemText className={classes.title} primary={title} />
              <ListItemText className={classes.value} primary={data[field]} />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Paper>
  );
};

export const CustomList = React.memo(FunctionComponent);

export default CustomList;
