export default theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        height: 36,
        color: theme.palette.default.text,
    },

    focus: {
        '& > span': {
            height: 2,
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
            bottom: -1,
            content: "' '",
            animation: 'expand 280ms',
            transformOrigin: 'center'
        }
    },

    inputFallback: {
        '& + label': {
            transform: 'translateY(-20px) scale(.85)',
            color: theme.palette.primary.main,
            animation: 'retract 200ms',
            left: -4
        }
    },

    input: {
        flex: 1,
        padding: 0,
        margin: 0,
        border: 'none',
        borderBottom: `1px solid ${theme.palette.default.text}`,
        outline: 'none',
        fontSize: 14,
        '&:focus + label': {
            transform: 'translateY(-20px) scale(.85)',
            color: theme.palette.primary.main,
            animation: 'retract 200ms',
            left: -4
        },
        '&:-webkit-autofill ~ span': {
            height: 2,
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
            bottom: -1,
            content: "' '",
            animation: 'expand 280ms',
            transformOrigin: 'center'
        },

        '&:-webkit-autofill + label': {
            transform: 'translateY(-20px) scale(.85)',
            color: theme.palette.primary.main,
            animation: 'retract 200ms',
            left: -4
        },
    },

    label: {
        position: 'absolute',
        fontSize: 14,
        left: 0,
        bottom: 10,
        padding: 0,
        cursor: 'text',
        transform: 'translateY(0) scale(1)',
        animation: 'collapse 200ms',
        color: theme.palette.default.text,
        userSelect: 'none'
    },

    '@keyframes retract': {
        from: {
            transform: 'translateY(0) scale(1)',
            left: 0,
        },
        to: {
            transform: 'translateY(-20px) scale(.85)',
            left: -4
        }
    },
    '@keyframes collapse': {
        from: {
            transform: 'translateY(-20px) scale(.85)',
            left: -4
        },
        to: {
            transform: 'translateY(0) scale(1)',
            left: 0
        }
    },
    '@keyframes expand': {
        from: {
            transform: 'scale(0)',
        },
        to: {
            transform: 'scale(1)'
        },
    }
})
