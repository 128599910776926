import React, { useState, useEffect } from "react";

import { useStyles } from "..";
import styles from "./styles";

import {
  MdAccountCircle,
  MdLocalGasStation,
  MdGpsFixed,
  MdLocalShipping,
  MdDirectionsCar,
  MdStreetview,
  MdNearMe,
} from "react-icons/md";

const icons = [
  MdAccountCircle,
  MdLocalShipping,
  MdDirectionsCar,
  MdLocalGasStation,
  MdGpsFixed,
  MdStreetview,
  MdNearMe,
];

let lastId = 0;

export const Spinner = () => {
  const classes = useStyles(styles);

  const [counter, setCounter] = useState(lastId);

  useEffect(() => {
    const increment = () => {
      lastId++;
      if (lastId === icons.length) {
        lastId = 0;
        setCounter(lastId);
      } else {
        setCounter(lastId);
      }
    };
    let frame;
    const interval = setInterval(() => {
      frame = requestAnimationFrame(increment);
    }, 1000);
    return () => {
      clearInterval(interval);
      cancelAnimationFrame(frame);
    };
  }, []);

  const Icon = icons[counter];
  return (
    <div className={classes.root}>
      <Icon className={classes.icon} />
      <div className={classes.loader} />
    </div>
  );
};

export default Spinner;
