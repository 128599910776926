export default (theme) => ({
  root: {
    width: "350px",
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    overflow: "hidden",
    background: "rgba(255,255,255,0.7)",
    transition: "background .9s",
    "&:hover": {
      background: "rgba(255,255,255,0.9)",
    },
  },
  paper: {
    width: "calc(100% - 20px)",
    height: "50%",
    maxWidth: 350,
    padding: 5,
    display: "flex",
    flexDirection: "column",
  },

  logo: {
    height: 100,
    width: 237,
  },

  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > span": {
      fontSize: 24,
      fontWeight: 400,
    },
    "& > div": {
      fontSize: 12,
      padding: "20px 20px 0",
    },
  },

  form: {
    width: "100%",
    flex: 1,
    padding: 5,
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  input: {
    width: "88%",
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "88%",
    marginTop: 20,
  },

  button: {
    width: "65%",
  },

  backButton: {
    fontSize: 14,
    color: "#1d5279",
    fontWeight: 600,
    marginRight: 20,
  },
});
