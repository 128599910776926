export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            margin: '10px 0'
        },
        '& > div:first-child': {
            margin: '20px 0 10px 0'
        }
    }
})