import React, { useContext } from "react";
import { context } from "../../components/Navbar/context";

import { useStyles, Button } from "..";
import styles from "./styles";
import clsx from "clsx";

import { FaBars } from "react-icons/fa";

const logo = require("../../assets/images/logo.png");
const logoChip = require("../../assets/images/logo-branca.png");

export const Appbar = ({ children, variant }) => {
  const classes = useStyles(styles);
  const drawer = useContext(context);

  const { expanded, locked, setLocked, setExpanded } = drawer;

  const rootStyle = clsx({
    [classes.permanent]: variant === "permanent",
    [classes.expanded]: expanded && variant === "permanent",
    [classes.temporary]: variant === "temporary",
  });

  const onMenuClick = () => {
    setLocked(!locked);
    setExpanded(!locked);
  };

  return (
    <div className={rootStyle}>
      <Button variant={"icon"} onClick={onMenuClick}>
        <FaBars className={classes.hamburguer} />
      </Button>
      <img src={logo} alt="logo" className={classes.logo} />
      <div className={classes.grow} />
        <img src={logoChip} alt="logo" className={classes.logo} />
      <div className={classes.grow} />
      {children}
    </div>
  );
};

export default Appbar;
