export default theme => ({
    wrapper: {
        position: 'relative',
        userSelect: 'none',
        '@media (max-width: 900px)': {
            flexDirection: 'row',
            alignItems: 'center'
        }
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',

    },

    avatar: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        overflow: 'hidden'
    },

    chevron: {
        color: theme.palette.default.dark,
        fontSize: 12,
        marginLeft: 5
    },

    image: {
        height: '100%',
        width: '100%',
    },

    pseudoImage: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        fontWeight: 600,
        color: 'white',
        backgroundColor: theme.palette.warning.main,
    },

    paper: {
        zIndex: 2600,
        width: 150,
        position: 'absolute',
        top: '100%',
        right: 0,
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.default.dark}`,
        borderRadius: '5px',
        transition: 'transform 180ms ease',
        transformOrigin: 'top',
        transform: 'scaleY(0)',
        '@media (max-width: 900px)': {
            left: 0
        }
    },

    expanded: {
        transform: 'scaleY(1)'
    },

    grow: {
        flexGrow: .9
    },

    item: {
        width: 'calc(100%-10px)',
        borderBottom: `1px solid ${theme.palette.default.main}`,
        padding: '10px 5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.default.text,
    },

    name: {
        fontSize: 14,
    },

    check: {
        color: theme.palette.default.dark,
        fontSize: 12
    }
})