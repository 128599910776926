import React from 'react'
import { withRouter } from 'react-router'

import { useStyles } from '..'
import styles from './jss/icon'
import clsx from 'clsx'

import { FaCar } from 'react-icons/fa'
import { MdLocalShipping as MdTruck } from 'react-icons/md'


const Icon = withRouter(({ history, id, categoria, status, ignicao, className }) => {
    const interpolation = { color: status }
    const classes = useStyles(styles, interpolation)

    const iconStyle = clsx({
        [classes.root]: true,
        [classes.disabled]: !ignicao.status && status === 'success'
    })

    const onClick = () => {
        history.push(`/monitoramento/rastreador/${id}`)
    }

    return categoria === 1 ?
        <MdTruck className={`${iconStyle} ${className}`} onClick={() => onClick()} title='Ver localização atual' /> :
        <FaCar className={`${iconStyle} ${className}`} onClick={() => onClick()} title='Ver localização atual' />
})

Icon.defaultProps = {
    className: ''
}

export default React.memo(Icon)