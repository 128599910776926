const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    minHeight:
      theme.dimensions.customHeight.small -
      2 * theme.dimensions.customBorder.medium,
    padding: theme.dimensions.customPadding.medium,
    cursor: "pointer",
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.primary,
  },
  hover: {
    "&:hover": {
      backgroundColor: theme.palette.grey["300"],
    },
  },
  container: {
    fontSize: "0.95em",
    overflow: "hidden",
  },
  label: {},
  details: {
    color: theme.palette.text.secondary,
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: theme.dimensions.customMargin.small,
  },
});

export default styles;
