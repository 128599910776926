export default theme => ({
    wrapper: {
        position: 'relative',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        overflow: 'hidden'
    },

    image: {
        width: '100%',
        height: '100%'
    },

    name: {
        fontSize: 12,
        color: theme.palette.default.text,
        marginLeft: 10,

    },

    chevron: {
        fontSize: 12,
        color: theme.palette.default.text,
        marginLeft: 10
    },

    paper: {
        zIndex: 2600,
        width: '100%',
        position: 'absolute',
        top: '100%',
        left: 5,
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.default.dark}`,
        borderRadius: '5px',
        transition: 'transform 180ms ease',
        transformOrigin: 'top',
        transform: 'scaleY(0)',

    },

    expanded: {
        transform: 'scaleY(1)'
    },

    item: {
        width: 'calc(100%-10px)',
        borderBottom: `1px solid ${theme.palette.default.main}`,
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.palette.default.text,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        '&:active': {
            backgroundColor: 'rgba(0,0,0,.2)'
        }
    },

    itemName: {
        fontSize: 14,
    },

    itemIcon: {
        fontSize: 12,
        color: theme.palette.default.text,
        margin: '0 5px'
    }
})