export default theme => ({
    root: {
        padding: 10
    },

    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 5
    },

    icon: {
        color: theme.palette.default.text,
        marginRight: 10
    },

    text: {
        fontSize: 14,
        color: theme.palette.default.text
    },

    image: {
        height: 20,
        width: 20,
        filter: 'invert()',
        marginRight: 8
    }
})