import React from 'react'

import { useStyles } from '..'
import styles from './jss/footer'

//i18n
import intl from 'react-intl-universal'

export const Footer = ({ velocidade, combustivel, odometro, telemetria }) => {
    const classes = useStyles(styles)

    return (
        <div className={classes.root}>
            <Item value={velocidade} label={intl.get('vehicle.footer.velocity')} />
            {telemetria && <Item value={combustivel} label={intl.get('vehicle.footer.fuel')} />}
            {telemetria && <Item value={odometro} label={intl.get('vehicle.footer.odometer')} />}
        </div>
    )
}

const Item = ({ value, label }) => {
    const classes = useStyles(styles)
    return (
        <div className={classes.item}>
            <div className={classes.value}>
                {value}
            </div>
            <div className={classes.label}>
                {label}
            </div>
        </div>
    )
}

export default Footer