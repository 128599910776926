const styles = (theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    width: theme.dimensions.customWidth.full,
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
  },
  title: {
    marginLeft: theme.dimensions.customMargin.xSmall,
    marginBottom: theme.dimensions.customMargin.xSmall,
    fontSize: "0.75em",
  },
  titleError: {
    marginLeft: theme.dimensions.customMargin.xSmall,
    marginBottom: theme.dimensions.customMargin.xSmall,
    fontSize: "0.75em",
    color: `${theme.palette.danger.dark} !important`,
  },
  container: {
    fontFamily: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    letterSpacing: "inherit",
    color: "inherit",
    "& :first-child": {
      fontFamily: "inherit",
      fontSize: "inherit",
      fontWeight: "inherit",
      letterSpacing: "inherit",
      color: "inherit",
    },
    "& :first-child :first-child": {
      boxSizing: "border-box",
      backgroundColor: theme.palette.background.paper,
      minHeight: theme.dimensions.customHeight.small,
      borderColor: theme.palette.text.secondary,
      borderStyle: "solid",
      borderWidth: theme.dimensions.customBorder.small,
      borderRadius: theme.dimensions.customBorderRadius.small,
      padding: `${theme.dimensions.customPadding.medium}px ${
        theme.dimensions.customPadding.medium +
        (theme.dimensions.customBorder.medium -
          theme.dimensions.customBorder.small)
      }px`,
      fontFamily: "inherit",
      fontSize: "0.95em",
      fontWeight: "inherit",
      letterSpacing: "inherit",
      color: "inherit",
      overflow: "hidden",
      cursor: "pointer",
    },
    "& :first-child :first-child:hover": {
      borderColor: theme.palette.text.primary,
    },
    "& :first-child :first-child:focus": {
      borderColor: theme.palette.primary.dark,
      borderWidth: theme.dimensions.customBorder.medium,
      padding: theme.dimensions.customPadding.medium,
    },
    "& :first-child :first-child::-webkit-calendar-picker-indicator": {
      marginLeft: theme.dimensions.customMargin.none,
      cursor: "pointer",
    },
    "& :first-child::before": {
      content: "none",
    },
    "& :first-child::after": {
      content: "none",
    },
  },
  nowContainer: {
    fontFamily: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    letterSpacing: "inherit",
    color: "inherit",
    "& :first-child": {
      fontFamily: "inherit",
      fontSize: "inherit",
      fontWeight: "inherit",
      letterSpacing: "inherit",
      color: "inherit",
    },
    "& :first-child :first-child": {
      boxSizing: "border-box",
      backgroundColor: theme.palette.background.paper,
      minHeight: theme.dimensions.customHeight.small,
      borderColor: theme.palette.text.secondary,
      borderStyle: "solid",
      borderWidth: theme.dimensions.customBorder.small,
      borderRadius: "5px 0 0 5px",
      padding: `${theme.dimensions.customPadding.medium}px ${
        theme.dimensions.customPadding.medium +
        (theme.dimensions.customBorder.medium -
          theme.dimensions.customBorder.small)
      }px`,
      fontFamily: "inherit",
      fontSize: "0.95em",
      fontWeight: "inherit",
      letterSpacing: "inherit",
      color: "inherit",
      overflow: "hidden",
      cursor: "pointer",
    },
    "& :first-child :first-child:hover": {
      borderColor: theme.palette.text.primary,
    },
    "& :first-child :first-child:focus": {
      borderColor: theme.palette.primary.dark,
      borderWidth: theme.dimensions.customBorder.medium,
      padding: theme.dimensions.customPadding.medium,
    },
    "& :first-child :first-child::-webkit-calendar-picker-indicator": {
      marginLeft: theme.dimensions.customMargin.none,
      cursor: "pointer",
    },
    "& :first-child::before": {
      content: "none",
    },
    "& :first-child::after": {
      content: "none",
    },
  },
  now: {
    display: "flex",
    alignItems: "center",
    marginLeft: 0,
    cursor: "pointer",
    borderColor: theme.palette.text.secondary,
    borderStyle: "solid",
    borderWidth: theme.dimensions.customBorder.small,
    borderRadius: "0 5px 5px 0",
    padding: 6,
    color: "#444444",
    "& > svg": {
      color: "#444444",
      marginLeft: 2,
    },
  },
});

export default styles;
