import React from "react";
import { useHistory } from "react-router";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import { CustomWrapper } from "..";

import styles from "./jss/CustomRedirectPage";

const FunctionComponent = (props) => {
  const {
    message = null,
    redirectMessage = intl.get("core.CustomRedirectPage.redirect"),
    redirectUri = "/",
  } = props;

  const history = useHistory();
  const classes = useStyles(styles, props);

  const redirect = () => {
    history.push(redirectUri);
  };

  return (
    <CustomWrapper classes={{ container: classes.container }} withPanel={false}>
      <div className={classes.font}>
        {message ? <div className={classes.statusText}>{message}</div> : null}
        <div className={classes.redirect} onClick={redirect}>
          {redirectMessage}
        </div>
      </div>
    </CustomWrapper>
  );
};

export const CustomRedirectPage = React.memo(FunctionComponent);

export default CustomRedirectPage;
