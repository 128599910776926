export default (theme) => ({
  permanent: {
    height: theme.navbar.height,
    backgroundColor: theme.navbar.background,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "margin 220ms ease",
    marginLeft: theme.drawer.width,
    boxShadow: theme.elevation[2],
    padding: "0 10px",
    zIndex: theme.zIndex.appBar,
  },

  temporary: {
    height: theme.navbar.height,
    backgroundColor: theme.navbar.background,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: theme.elevation[2],
    padding: "0 10px",
    zIndex: theme.zIndex.appBar,
  },

  grow: {
    flexGrow: 1,
  },

  expanded: {
    marginLeft: theme.drawer.expandedWidth,
  },

  hamburguer: {
    fontSize: 16,
    color: theme.navbar.color,
    padding: 3,
  },

  logo: {
    height: theme.navbar.height - 10,
    "@media (max-width: 500px)": {
      display: "none",
    },
  },
});
