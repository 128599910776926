export default (theme) => ({
  root: {
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  paper: {
    width: "calc(100% - 10px)",
    height: "60%",
    maxWidth: 370,
    padding: 5,
    display: "flex",
    flexDirection: "column",
    boxShadow:
      "0 23px 20px -20px rgba(115,162,208,0), 0 0 15px rgba(115,162,208,0)",
  },

  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > span": {
      fontSize: 24,
      fontWeight: 400,
    },
    "& > div": {
      fontSize: 12,
      padding: "20px 20px 0",
    },
  },

  form: {
    flex: 1,
    padding: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    maxHeight: "50%",
  },

  input: {
    width: "100%",
    marginBottom: 7,
  },

  footer: {
    flex: 0.3,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },

  button: {
    width: "100%",
    marginBottom: 7,
  },
});
