import { getBackgroundColor, getTextColor } from "../../styles/theme/helpers"

export default theme => ({
    root: {
        position: 'absolute',
        top: 10,
        right: 10,
        minWidth: 350,
        maxWidth: 350,
        padding: '15px 10px',
        boxSizing: 'border-box',
        backgroundColor: props => getBackgroundColor(props.color)(theme),
        color: props => getTextColor(props.color)(theme),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        animation: 'expandSnack .25s',
        zIndex: theme.zIndex.snackbar,
    },

    retract: {
        animation: 'retractSnack .2s',
        transform: 'scale(0)'
    },

    title: {
        marginLeft: 15,
    },

    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        height: 18,
        width: 18,
        borderRadius: '50%',
        padding: 2
    },

    icon: {
        color: props => getBackgroundColor(props.color)(theme),
        fontSize: 18,
    },


    grow: {
        flexGrow: 1
    },

    '@keyframes expandSnack': {
        from: {
            transform: 'scale(0)'
        },
        to: {
            transform: 'scale(1)'
        }
    },

    '@keyframes retractSnack': {
        from: {
            transform: 'scale(1)'
        },
        to: {
            transform: 'scale(0)'
        }
    }

})