export const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
  },
  container: {
    height: "fit-content",
    verticalAlign: "middle",
  },
  icon: {
    display: "inline-block",
    verticalAlign: "bottom",
    margin: "0 0.25em",
    height: "1.2em",
    width: "1.2em",
    borderRadius: "0.6em",
    borderStyle: "solid",
    borderWidth: "2px",
    boxSizing: "border-box",
  },
  warningIcon: {
    background: theme.palette.warning.dark,
  },
  errorIcon: {
    background: theme.palette.error.dark,
  },
  text: {
    color: theme.palette.text.primary,
  },
  warningMessage: {
    color: theme.palette.warning.dark,
    fontSize: theme.typography.standard.fontSizeError,
  },
  errorMessage: {
    color: theme.palette.error.dark,
    fontSize: theme.typography.standard.fontSizeError,
  },
  none: {
    display: "none",
  },
});

export default styles;
