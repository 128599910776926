import React, { useState } from 'react'

import { useStyles } from '..'
import styles from './jss/ripple'
import { Wave } from './Wave'
import { getOffset } from '../../utils/helpers'

export const Ripple = ({ container, color, center, icon }) => {
    const [waves, setWaves] = useState([])
    const [active, setActive] = useState({ radius: 0, top: 0, left: 0, color })

    const interpolation = { ...active, icon }
    const classes = useStyles(styles, interpolation)


    const onMouseDown = e => {
        const { pageX, pageY } = e
        const { offsetLeft, offsetTop, offsetParent, clientWidth, clientHeight } = container.current
        const { accTop, accLeft } = getOffset(offsetParent)

        const offLeft = offsetLeft + accLeft
        const offTop = offsetTop + accTop

        const x = pageX - offLeft
        const y = pageY - offTop

        const top = Math.round((y / clientHeight) * 100)
        const left = Math.round((x / clientWidth) * 100)

        const bigger = Math.max(clientHeight, clientWidth)
        const radius = Math.round(bigger / 2)

        const wave = { top: center ? 50 : top, left: center ? 50 : left, radius, color }
        setWaves(waves => waves.concat(wave))
        setActive(wave)
    }

    const onMouseLeave = () => setWaves(w => w.filter((_, i) => i + 1 === w.length))
    return (
        <div
            className={classes.root}
            onMouseDown={onMouseDown}
            onMouseLeave={onMouseLeave}
        >

            {
                waves.map((wave, i) => (
                    <Wave {...wave} key={i} icon={icon} />
                ))

            }
        </div>
    )
}

Ripple.defaultProps = {
    center: false,
    icon: false
}