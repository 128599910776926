import React, { useRef } from "react";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import clsx from "clsx";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ScrollSync,
} from "react-virtualized";
import { CircularProgress } from "@material-ui/core";
import { CustomOptionsItem } from "..";

import { theme } from "../../styles";
import styles from "./jss/CustomOptions";

const borderHeight = 2 * theme.dimensions.customBorder.medium;
const borderWidth = 2.5 * theme.dimensions.customBorder.medium;
const rowHeight = theme.dimensions.customHeight.small - borderHeight;

const FunctionComponent = (props) => {
  const {
    items = [],
    handleClick = () => {},
    toggleAll = null,
    feminine = false,
    error = false,
    loading = false,
  } = props;
  const toggleAllCount = toggleAll ? 1 : 0;
  const rowCount = items.length + toggleAllCount;
  const rootSize = {
    height:
      (rowCount < 5 ? rowCount * rowHeight : 5 * rowHeight) + borderHeight,
  };

  const cacheRef = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: rowHeight,
    })
  );
  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.error]: error,
  });

  if (loading)
    return (
      <div className={rootStyle}>
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  else if (!items.length)
    return (
      <div className={rootStyle}>
        <CustomOptionsItem
          item={{
            label: intl.get("core.CustomOptions.noItems"),
            active: false,
          }}
        />
      </div>
    );
  else
    return (
      <ScrollSync>
        {({ scrollTop, onScroll }) => {
          return (
            <div className={rootStyle} style={rootSize}>
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    scrollTop={scrollTop}
                    onScroll={onScroll}
                    width={width - borderWidth}
                    height={height - borderHeight}
                    rowHeight={cacheRef.current.rowHeight}
                    deferredMeasurementCache={cacheRef.current}
                    style={{ outline: "none" }}
                    rowCount={rowCount}
                    rowRenderer={({ key, index, style, parent }) => {
                      return (
                        <CellMeasurer
                          key={key}
                          cache={cacheRef.current}
                          parent={parent}
                          columnIndex={0}
                          rowIndex={index}
                        >
                          <div style={style}>
                            {toggleAll && index === 0 ? (
                              <CustomOptionsItem
                                item={{
                                  id: 0,
                                  label: feminine
                                    ? intl.get("core.CustomOptions.allFeminine")
                                    : intl.get("core.CustomOptions.all"),
                                  active:
                                    items.filter((item) => !item.active)
                                      .length === 0,
                                }}
                                handleClick={toggleAll}
                              />
                            ) : (
                              <CustomOptionsItem
                                item={items[index - toggleAllCount]}
                                handleClick={handleClick}
                              />
                            )}
                          </div>
                        </CellMeasurer>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          );
        }}
      </ScrollSync>
    );
};

export const CustomOptions = React.memo(FunctionComponent);

export default CustomOptions;
