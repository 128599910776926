import { setSnackbar } from './Snackbar'
import { path } from '../../utils/api'
import axios from 'axios'

export const forgotPassword = payload => {
    return (dispatch) => {
      
        const endpoint = path + '/forgotpassword'
        const headers = { headers: { } }
        axios.post(endpoint, payload, headers)
            .then(res => dispatch(setSnackbar({ open: true, title: res.data.msg, duration: 4000, color: 'success' })))
            .catch(err => dispatch(setSnackbar({ open: true, title: err.response.data.msg, duration: 4000, color: 'danger' })))

    }
}

export const resetPassword = payload => {
    return (dispatch) => {

        const endpoint = path + '/resetpassword'
        const headers = { headers: { } }
        axios.post(endpoint, payload, headers)
            .then(res => {
                console.log(res.data)
                dispatch(setSnackbar({ open: true, title: res.data.msg, duration: 4000, color: 'success' }))
            })
            .catch(err => dispatch(setSnackbar({ open: true, title: err.response.data.msg, duration: 4000, color: 'danger' })))

    }
}


