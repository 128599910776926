import React, { useState } from 'react'

import { useStyles } from '..'
import styles from './jss/tooltip'

import Badge from './Badge'
import clsx from 'clsx'


export const Tooltip = ({ Icon, image, alerta, descricao, isLast, className, badgeStyle, onClick, pointer }) => {
    const classes = useStyles(styles)

    const [showDescription, setDescription] = useState(false)

    const tooltipStyle = clsx({
        [classes.tooltip]: true,
        [classes.pointer]: pointer
    })

    const descriptionStyle = clsx({
        [classes.description]: true,
        [classes.showDescription]: showDescription,
        [classes.isLast]: isLast
    })



    return (
        <div
            className={`${tooltipStyle} ${className}`}
            onMouseEnter={() => setDescription(true)}
            onMouseLeave={() => setDescription(false)}
            onClick={onClick}
        >
            {Boolean(alerta) && <Badge color={alerta.color} className={badgeStyle} />}
            {Boolean(descricao) && <div className={descriptionStyle}>{descricao}</div>}
            {Icon && <Icon className={classes.tooltipIcon} />}
            {image && <img src={image} className={classes.image} alt='belt' />}
        </div>
    )
}

Tooltip.defaultProps = {
    className: '',
    badgeStyle: '',
    onClick: () => { },
    pointer: false
}

export default Tooltip