import React, { useRef, useState, useEffect, cloneElement } from 'react'
import { useStyles } from '..'
import styles from './jss/icon'
import clsx from 'clsx'
import { isPresetColor } from '../../utils/helpers'

export const Icon = ({
    children,
    className,
    style,
    color,
    size,
    rippleColor,
    disabled,
    active,
    ripple,
    onMouseDown: customMouseDown,
    onMouseUp: customMouseUp,
    onMouseLeave: customMouseLeave,
    ...buttonProps
}) => {
    const interpolation = { color, size, rippleColor }
    const classes = useStyles(styles, interpolation)
    const [isPressed, setIsPressed] = useState(false)
    const [animate, setAnimate] = useState(false)
    const self = useRef(null)


    useEffect(() => {
        let timeout = null
        let frame = null

        if (animate && !isPressed) {
            timeout = setTimeout(() => {
                frame = requestAnimationFrame(() => setAnimate(false))
            }, 180)
        }

        return () => {
            clearTimeout(timeout)
            cancelAnimationFrame(frame)
        }
    }, [animate, isPressed])


    const rootStyle = clsx({
        [classes.root]: true,
        [classes[color]]: isPresetColor(color),
        [classes.disabled]: disabled
    })


    const growStyle = clsx({
        [classes.grow]: true,
        [classes.animateGrow]: (animate || active) && ripple,
        [classes.hideGrow]: (!animate && !active),
        [classes.active]: active,

    })


    const onMouseDown = () => {
        setIsPressed(true)
        setAnimate(true)
    }

    const mouseDownHandler = e => {
        onMouseDown(e)
        if (typeof customMouseDown === 'function')
            customMouseDown(e)
    }

    const mouseUpHandler = e => {
        setIsPressed(false)
        if (typeof customMouseUp === 'function')
            customMouseUp(e)
    }

    const mouseLeaveHandler = e => {
        setIsPressed(false)
        if (typeof customMouseLeave === 'function')
            customMouseLeave(e)
    }

    const buttonStyle = `${rootStyle} ${className}`
    return (
        <button
            className={buttonStyle}
            style={style}
            ref={self}
            onMouseDown={mouseDownHandler}
            onMouseUp={mouseUpHandler}
            onMouseLeave={mouseLeaveHandler}
            {...buttonProps}
        >
            {cloneElement(children, { fontSize: size })}
            <span className={growStyle} />
        </button>
    )
}

Icon.defaultProps = {
    className: '',
    style: {},
    color: 'primary',
    disabled: false,
    active: false,
    size: 22,
    ripple: true
}