import React, { useState, useEffect } from 'react'
import { context } from './context'

const { Provider } = context

export const DrawerProvider = ({ children }) => {
    const [expanded, setExpanded] = useState(false)
    const [locked, setLocked] = useState(false)
    const [variant, setVariant] = useState('permanent')
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const listener = () => setWidth(window.innerWidth)
        window.addEventListener('resize', listener)
        return () => window.removeEventListener('resize', listener)
    }, [])

    useEffect(() => {
        if (width < 1281 && variant === 'permanent')
            setVariant('temporary')
        if (width > 1280 && variant === 'temporary')
            setVariant('permanent')
    }, [width, variant])


    const state = {
        expanded,
        setExpanded,
        locked,
        setLocked,
        variant,
        setVariant,
        width
    }

    if (locked && variant === 'temporary') setLocked(false)
    if (locked && !expanded) setExpanded(true)

    return (
        <Provider value={state}>
            {children}
        </Provider>
    )
}
