import React from "react";
import { useStyles } from "../../hooks";
import { CustomWrapper, Spinner } from "..";

import styles from "./jss/CustomPreloader";

const FunctionComponent = (props) => {
  const { status = "", withNavTabs = false } = props;
  const classes = useStyles(styles, props);

  return (
    <CustomWrapper
      classes={{ container: classes.container }}
      withNavTabs={withNavTabs}
      withPanel={false}
    >
      <Spinner />
      <div className={classes.font}>
        <div className={classes.status}>{status}</div>
      </div>
    </CustomWrapper>
  );
};

export const CustomPreloader = React.memo(FunctionComponent);

export default CustomPreloader;
