import React from "react";

import { useStyles } from "../../styles";
import styles from "./jss/form";

import Button from "@material-ui/core/Button";

import { InputLogin } from "../../core";
import LoginForgot from "./LoginForgot";

export const Form = ({ state, onChange, onSubmit, openForgot, loading }) => {
  const classes = useStyles(styles);

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      if (state.email && state.password) onSubmit();
    }
  };

  return (
    <>
      <div className={classes.form}>
        <InputLogin
          type="email"
          value={state.email}
          onChange={onChange}
          name="email"
          className={classes.input}
          label="Email"
          onKeyDown={onKeyDown}
          autoFocus
        />
        <InputLogin
          type="password"
          value={state.password}
          onChange={onChange}
          name="password"
          className={classes.input}
          label="Senha"
          onKeyDown={onKeyDown}
        />
      </div>
      <div className={classes.footer}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={loading}
        >
          Login
        </Button>
        <LoginForgot onClick={() => openForgot(true)} />
      </div>
    </>
  );
};

export default Form;
