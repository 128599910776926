export default (theme) => ({
  loginForgotText: {
    "& > h5": {
      fontFamily: theme.typography.standard.fontFamily,
      letterSpacing: theme.typography.standard.letterSpacing,
      fontSize: 12,
      color: "#444444",
      textAlign: "center",
      fontWeight: 600,
      padding: 10,
      "& > .link": {
        fontSize: 12,
        color: "#165c82",
        textAlign: "center",
        fontWeight: 500,
        paddingLeft: 5,
        cursor: "pointer",
        transition: "font-weigth .2s",
        "&:hover": {
          fontWeight: 600,
        },
      },
    },
  },
});
