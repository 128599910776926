import React from 'react'

import { useStyles } from '../styles'
import styles from './jss/error'
import { FaFrown } from 'react-icons/fa'

import { Wrapper } from '../core'

export const Error = () => {
    const classes = useStyles(styles)

    const reload = () => {
        window.location.reload()
    }

    return (
        <Wrapper className={classes.wrapper}>
            <div className={classes.icon}><FaFrown /></div>
            <div className={classes.title}>Ocorreu um erro interno</div>
            <div className={classes.redirect} onClick={() => reload()}>Clique para atualizar a página</div>
        </Wrapper>
    )
}

export default Error