const background2 = require("../../../assets/images/backgound/background1.jpg");
const background1 = require("../../../assets/images/backgound/background2.jpg");
const background3 = require("../../../assets/images/backgound/background3.jpg");
//const background1 = require("../../../assets/images/background2.jpg");

export default (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightBold,
    letterSpacing: theme.typography.standard.letterSpacing,
  },
  backgound: {
    width: "100vw",
    height: "100vh",
    background: "linear-gradient(to right, #136a8a, #267871)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$changeBackgroundImage 15s infinite",
    transition: "background-image .1s ease-in-out",
    "-webkit-transition": "background-image .1s ease-in-out",
  },

  "@keyframes changeBackgroundImage": {
    "0%": {
      backgroundImage: `url(${background1})`,
    },
    "33%": {
      backgroundImage: `url(${background2})`,
    },
    "66%": {
      backgroundImage: `url(${background3})`,
    },
    "100%": {
      backgroundImage: `url(${background1})`,
    },
  },

  paper: {
    width: "calc(100% - 20px)",
    height: "60%",
    maxHeight: 420,
    maxWidth: 370,
    padding: 5,
    display: "flex",
    background: "rgba(255,255,255,0.5)",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    position: "fixed",
    zIndex: 10,
    right: "50px",
    borderRadius: "5px",
    overflow: "hidden",
    transition: "background .8s",
    "&:hover": {
      background: "rgba(255,255,255,0.7)",
    },
    "@media(max-width: 693px)": {
      right: "calc(50% - 175px)",
      left: "calc(50% - 175px)",
    },
  },

  logoContainer: {},

  formContainer: {
    width: "100%",
    padding: 5,
  },

  logo: {
    height: 100,
    width: 237,
  },

  loginFormTitle: {
    "& > h3": {
      fontSize: 24,
      color: "#444444",
      textAlign: "center",
      fontWeight: 400,
      marginTop: 8,
    },
  },

  title: {
    flex: 0.1,
    padding: 10,
    paddingTop: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },

  grow: {
    flexGrow: 1,
  },
});
