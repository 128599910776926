export default (theme) => ({
  permanent: {
    marginLeft: theme.drawer.width,

    transition: theme.transitions.drawer("margin"),
  },

  temporary: {},

  expanded: {
    marginLeft: theme.drawer.expandedWidth,
  },
});
