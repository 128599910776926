import React from "react";

import { useStyles } from "../../hooks";
import styles from "./jss/customLoading";

const FunctionComponent = ({ classe }) => {
  const classes = useStyles(styles);

  return (
    <div className={classe ? classe : classes.row}>
      <div className={classes.loader}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const CustomLoading = React.memo(FunctionComponent);

export default CustomLoading;
