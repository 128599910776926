export default theme => ({
    root: {
        display: 'flex',
        margin: 5,
        userSelect: 'none',
        border: props => {
            if (props.off || props.status === 'success')
                return `none`
            return `2px solid ${theme.palette[props.status].main}`
        },
        boxSizing: 'border-box'
    },

    iconContainer: {
        backgroundColor: props => theme.palette[props.status].main,
        border: props => `2px solid ${theme.palette[props.status].dark}`,
        padding: 5,
        borderRadius: '50%',
        height: 20,
        width: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    disabled: {
        backgroundColor: theme.palette.default.dark,
        border: `2px solid ${theme.palette.default.text}`,
    },

    icon: {
        fontSize: 22,
        color: props => theme.palette[props.status].text,
    },

    title: {
        fontSize: 12,
        color: theme.palette.default.text,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        whiteSpace: 'nowrap',
        minWidth: 115
    },

    indicators: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },

    tooltip: {
        height: 13,
        width: 13,
        padding: 3,
    },


    '@media (max-width: 555px)': {
        tooltip: {
            height: 10,
            width: 10,
            padding: 3,
        },
    },

    badge: {
        top: -5,
        left: 10,
        height: 14,
        width: 14,
    },

    mobile: {
        fontSize: 14,
        color: theme.palette.primary.main,
        fontWeight: 600,
        cursor: 'pointer'
    }
})