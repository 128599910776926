import { getColor } from "../../../utils/helpers"

export default theme => ({
    root: ({ radius, top, left, color, icon }) => ({
        position: 'absolute',
        top: `calc(${top}% - ${radius / 2}px)`,
        left: `calc(${left}% - ${radius / 2}px)`,
        height: radius,
        width: radius,
        borderRadius: '50%',
        backgroundColor: getColor(color, theme),
        animation: '$ripple .8s',
        animationTimingFunction: 'cubic-bezier(.25, .46, .45, .94)',
    }),

    '@keyframes ripple': {
        from: {
            transform: 'scale(0)',
            opacity: .15
        },
        to: {
            transform: 'scale(5)',
            opacity: 0
        }
    },

    '@keyframes rippleIconWave': {
        from: {
            transform: 'scale(0)',
            opacity: .5
        },
        to: {
            transform: 'scale(5)',
            opacity: 0
        }
    }
})