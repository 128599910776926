import React from 'react'
import { createPortal } from 'react-dom'

import { useStyles } from '..'
import styles from './styles'

export const Overlay = ({ children, hide, onOverlayClick }) => {
    const classes = useStyles(styles)

    const jsx = !hide ? (
        <div className={classes.overlay} onClick={onOverlayClick}>
            {children}
        </div>
    ) : <div>{children}</div>

    return createPortal(jsx, document.getElementById('root'))
}

Overlay.defaultProps = {
    hide: false,
    onOverlayClick: () => { }
}

export default Overlay

