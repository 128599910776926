import { AuthTypes as types } from '../types'

const initialState = {
    token: null,
    id: null,
    profile: null,
    company: null,
    trocarSenha: null, 
    manutencao: null,
    companies: [],
    selectedCompanies: [],
    permissions: [],
    menuPermissions: []
}

export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.STORE_TOKEN: return storeToken(state, action)
        case types.STORE_MENU: return storeMenu(state, action)
        case types.STORE_COMPANIES: return storeCompanies(state, action)
        case types.STORE_PERMISSIONS: return storePermissions(state, action)
        case types.SELECT_COMPANY: return selectCompany(state, action)
        default: return state
    }
}

const storeToken = (state, action) => ({
    ...state,
    token: action.data.token,
    id: action.data.id,
    language: action.data.idioma,
    profile: action.data.perfil,
    company: action.data.empresa,
    trocarSenha: action.data.trocarSenha,
    manutencao: action.data.manutencao
})

const storeMenu = (state, action) => {
    return {
        ...state,
        menuPermissions: action.data
    }
}

const storeCompanies = (state, action) => {
    return {
        ...state,
        companies: action.data,
        selectedCompanies: action.data
    }
}

const storePermissions = (state, action) => {
    return {
        ...state,
        permissions: action.data
    }
}

const selectCompany = (state, action) => {
    return {
        ...state,
        selectedCompanies: action.data
    }
}