export default (theme) => ({
  root: {
    position: "absolute",
    top: `calc(100% - ${theme.dimensions.customBorder.medium}px)`,
    zIndex: theme.zIndex.modal,
    width: theme.dimensions.customWidth.full,
    boxSizing: "border-box",
    background: theme.palette.background.paper,
    margin: theme.dimensions.customMargin.none,
    borderStyle: "solid",
    borderColor: theme.palette.primary.dark,
    borderWidth: theme.dimensions.customBorder.medium,
    borderRadius: `${theme.dimensions.customBorderRadius.none} ${theme.dimensions.customBorderRadius.none} ${theme.dimensions.customBorderRadius.small}px ${theme.dimensions.customBorderRadius.small}px`,
    padding: theme.dimensions.customPadding.none,
    transformOrigin: "top",
  },
  error: {
    borderColor: theme.palette.error.dark,
  },
  loading: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: theme.dimensions.customPadding.xLarge,
  },
});
