import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";
import { CustomTitle, CustomInput } from "..";

import styles from "./jss/customTextInput";

export const CustomTextInput = React.memo(
  withStyles(styles)((props) => {
    const {
      classes,
      id,
      title = "",
      ruleMessage = "",
      input = "",
      setInput = () => "",
      placeholder = "",
      focused = false,
      showOptions = false,
      disabled = false,
      handleClick = () => {},
      min = Number.NEGATIVE_INFINITY,
      max = Number.POSITIVE_INFINITY,
      validInput = false,
      ref = null,
      mask = false,
    } = props;

    const [error, setError] = useState("");
    const [valid, setValid] = useState(false);
    const rootStyle = clsx({
      [classes.root]: true,
      [classes.font]: true,
    });

    const validate = (input) => {
      if (input.length < min || input.length > max) {
        setError(ruleMessage ? ruleMessage : "Erro");
        return false;
      }
      return true;
    };

    const onChange = (input) => {
      setValid(validate(input));
      //validInput(validate(input));
      setInput(input);
    };

    return (
      <div className={rootStyle}>
        {valid === false && (
          <CustomTitle
            title={title}
            classes={{
              container: classes.customTitleContainer,
            }}
            message={error}
            messageType={error ? "error" : ""}
          />
        )}
        <div style={{ marginTop: 3 }}>
          <CustomInput
            id={id}
            input={input}
            setInput={onChange}
            placeholder={placeholder}
            valid={valid}
            ref={ref}
            mask={mask}
            disabled={disabled}
            onClick={handleClick}
            focused={focused}
            showOptions={showOptions}
          />
        </div>
      </div>
    );
  })
);

export default CustomTextInput;
