import { DialogTypes as types } from '../types'


const initialState = {
    open: false,
    title: '',
    icon: 'error_outline',
    content: '',
    actions: {
        confirm: () => null,
        cancel: () => null,
        ok: () => null
    }
}

export const DialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_DIALOG:
            return {
                ...state,
                ...action.data,
                open: true
            }

        case types.CLOSE_DIALOG:
            return {
                ...state,
                open: false
            }

        default:
            return state
    }
}