import React, { useRef } from "react";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import clsx from "clsx";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { Tooltip, IconButton } from "@material-ui/core";
import Table, { MTableToolbar } from "material-table";

import {
  styles,
  headerStyle,
  headerStyleWithSorting,
  cellStyle,
} from "./jss/CustomTable";

const FunctionComponent = (props) => {
  const {
    tableRef = null,
    components = null,
    data = [],
    columns: _columns = [],
    title = "",
    minBodyHeight = null,
    maxBodyHeight = null,
    exportFileName = null,
    exportCsvButton = false,
    exportPdfButton = false,
    padding = "dense",
    paging = true,
    paginationType = "normal",
    pageSize = Math.ceil(Math.floor((window.innerHeight - 192) / 48) / 5) * 5,
    pageSizeOptions = [5, 10, 15, 20, 25, 30],
    draggable = false,
    emptyRowsWhenPaging = false,
    search = false,
    sorting = false,
    toolbar = false,
    grouping = false,
    noCsvExport = false,
    noXlsExport = false,
    noPdfExport = false,
    onExportReport = null,
    fixedColumns = {},
    rowStyle = () => {},
  } = props;

  const columns = _columns.map((_column) => ({
    ..._column,
    headerStyle: sorting
      ? { ...headerStyle, ...headerStyleWithSorting, ..._column.headerStyle }
      : { ...headerStyle, ..._column.headerStyle },
    cellStyle: { ...cellStyle, ..._column.cellStyle },
  }));

  const counterRef = useRef(0);
  console.log(`Table render: ${counterRef.current++}`);

  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });

  const ExportReport = () => {
    return (
      <div className={classes.exportIconsContainer}>
        {!noCsvExport && (
          <Tooltip
            title={intl.get("core.CustomTable.exportCSV")}
            placement="top"
          >
            <IconButton onClick={() => onExportReport("csv")}>
              <FaFileCsv />
            </IconButton>
          </Tooltip>
        )}
        {!noXlsExport && (
          <Tooltip
            title={intl.get("core.CustomTable.exportXLS")}
            placement="top"
          >
            <IconButton onClick={() => onExportReport("xls")}>
              <FaFileExcel />
            </IconButton>
          </Tooltip>
        )}
        {!noPdfExport && (
          <Tooltip
            title={intl.get("core.CustomTable.exportPDF")}
            placement="top"
          >
            <IconButton onClick={() => onExportReport("pdf")}>
              <FaFilePdf />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <div className={rootStyle}>
      <Table
        style={{ boxShadow: "none" }}
        tableRef={tableRef}
        data={data}
        title={title}
        columns={columns}
        options={{
          grouping,
          exportFileName,
          exportButton:
            exportCsvButton || exportPdfButton
              ? { csv: exportCsvButton, pdf: exportPdfButton }
              : false,
          fixedColumns,
          padding,
          paging,
          paginationType,
          minBodyHeight,
          maxBodyHeight,
          pageSize,
          pageSizeOptions,
          draggable,
          emptyRowsWhenPaging,
          search,
          sorting,
          toolbar,
          rowStyle
        }}
        components={
          (components || onExportReport) && {
            Toolbar: (props) => (
              <div className={classes.toolbarContent}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {components && components()}
                </div>
                <div className={classes.toolbarContainer}>
                  <MTableToolbar {...props} />
                </div>
                <div className={classes.exportContent}>
                  {onExportReport && ExportReport()}
                </div>
              </div>
            ),
          }
        }
        localization={{
          pagination: {
            labelDisplayedRows: intl.get("core.CustomTable.labelDisplayedRows"),
            labelRowsSelect: intl.get("core.CustomTable.labelRowsSelect"),
            labelRowsPerPage: intl.get("core.CustomTable.labelRowsPerPage"),
            firstAriaLabel: intl.get("core.CustomTable.firstAriaLabel"),
            firstTooltip: intl.get("core.CustomTable.firstTooltip"),
            previousAriaLabel: intl.get("core.CustomTable.previousAriaLabel"),
            previousTooltip: intl.get("core.CustomTable.previousTooltip"),
            nextAriaLabel: intl.get("core.CustomTable.nextAriaLabel"),
            nextTooltip: intl.get("core.CustomTable.nextTooltip"),
            lastAriaLabel: intl.get("core.CustomTable.lastAriaLabel"),
            lastTooltip: intl.get("core.CustomTable.lastTooltip"),
          }, 
          grouping: {
            placeholder: intl.get("core.CustomTable.groupPlaceholder"),
            groupedBy: intl.get("core.CustomTable.groupedBy"),
          },
          toolbar: {
            searchPlaceholder: intl.get("core.CustomTable.searchPlaceholder"),
            searchTooltip: intl.get("core.CustomTable.searchTooltip"),
            exportTitle: intl.get("core.CustomTable.export"),
            exportAriaLabel: intl.get("core.CustomTable.export"),
            exportCSVName: intl.get("core.CustomTable.exportCSV"),
            exportPDFName: intl.get("core.CustomTable.exportPDF"),
          },
          body: {
            emptyDataSourceMessage: intl.get(
              "core.CustomTable.emptyDataSourceMessage"
            ),
          },
          header: {
            actions: intl.get("core.CustomTable.actions"),
          },
        }}
      />
    </div>
  );
};

export const CustomTable = React.memo(FunctionComponent);

export default CustomTable;