import { UserTypes as types } from '../types'

const initialState = {
    id: null,
    company_id: null,
    company: null,
    profile_id: null,
    profile: null,
    name: null,
    email: null,
    language: null,
    status: null,
    image: null,
    users: [],
    uservehicles: [],
    usercompanies: []
}

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.STORE_USER:
            return storeUser(state, action)
        case types.FETCH_USERS:
            return fetchUsers(state, action)
        case types.FETCH_USERVEHICLES:
            return fetchUserVehicles(state, action)
        case types.FETCH_USERCOMPANIES:
            return fetchUserCompanies(state, action)
        default:
            return state
    }
}

const storeUser = (state, action) => ({
    ...state,
    id: action.data.usuario,
    company_id: action.data.id_empresa,
    company: action.data.nome_fantasia,
    profile_id: action.data.id_perfil,
    profile: action.data.desc_profile,
    name: action.data.nome,
    email: action.data.email,
    language: action.data.idioma,
    status: action.data.status,
    image: action.data.imagem
})

const fetchUsers = (state, action) => ({
    ...state,
    users: action.data
})

const fetchUserVehicles = (state, action) => ({
    ...state,
    uservehicles: action.data
})

const fetchUserCompanies = (state, action) => ({
    ...state,
    usercompanies: action.data
})