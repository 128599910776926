import React from "react";
import { useStyles } from "../../hooks";
import clsx from "clsx";
import { format } from "date-fns";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { FaRegClock } from "react-icons/fa";
import { CustomTitle } from "..";

import styles from "./jss/CustomDateTimePicker";

const FunctionComponent = (props) => {
  const {
    title = "",
    value = format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    setValue = () => format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    type = "datetime-local",
    customFormat = "yyyy-MM-dd'T'HH:mm",
    error = false,
    errorMessage = "",
    inputProps = {},
    now = false,
  } = props;

  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });

  const handleChange = (evt) => setValue(evt.target.value);

  let fix = process.env.REACT_APP_FIX_TIME
    ? parseInt(process.env.REACT_APP_FIX_TIME)
    : 0;

  const handleNow = (e) => {
    setValue(format(new Date(Date.now() + fix), customFormat));
    e.stopPropagation();
  };

  return (
    <div className={rootStyle}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.title,
          }}
          title={title}
          message={error ? errorMessage : null}
          messageType={error ? "error" : null}
        />
      )}
      <div style={{ display: "flex" }}>
        <TextField
          classes={{ root: now ? classes.nowContainer : classes.container }}
          type={type}
          fullWidth={true}
          value={value}
          onChange={handleChange}
          InputProps={{ inputProps }}
        />
        {now && (
          <>
            <Tooltip title={"Agora"} placement={"top"}>
              <span className={classes.now} onClick={(e) => handleNow(e)}>
                <FaRegClock />
              </span>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export const CustomDateTimePicker = React.memo(FunctionComponent);

export default CustomDateTimePicker;
