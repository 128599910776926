export default theme => ({
    root: {
        margin: 5,
        userSelect: 'none',
        overflowX: 'hidden'
    },

    grow: {
        flexGrow: 1
    },

    horizontal: {
        margin: 5,
        userSelect: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 10
    },

    horizontalIcon: {
        fontSize: 22,
        padding: 5,
        height: 25,
        width: 25,
    },

    horizontalIndicators: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        paddingTop: 0,
        marginLeft: 10
    },

    horizontalTitle: {
        fontSize: 12,
        color: '#444444',
        fontWeight: 700,
        marginBottom: 5
    },

    tooltips: {
        marginLeft: -5,
    },

    horizontalFooter: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },

    horizontalContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    speed: {
        fontSize: 14,
        fontWeight: 800,
        padding: 3,
        borderRadius: '50%',
        height: 15,
        width: 15,
        border: '3px solid red',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    odometer: {
        fontSize: 12,
        height: 20,
        padding: 3,
        color: '#394047',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    legend: {
        fontSize: 12,
        fontWeight: 600,
        color: '#394047'
    },

    body: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box'
    },

    leftPanel: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    rightPanel: {
        width: '70%',
    },
})