import { ProfilePermissionsTypes as types } from '../types'

const initialState = {
    profilepermissions: [],
    checkeds: [],
    expandeds: ['root'],
    permissoesUI: []
}

export const ProfilePermissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_PROFILEPERMISSIONS: return storeProfilePermissions(state, action)

        default: return state
    }
}

const storeProfilePermissions = (state, action) => {
    const { nodes, permissoesUI } = action.data

    if(nodes === null) return { ...state, profilepermissions: [], checkeds: [] }

    const results = []

    const mapChildren = node => {
        if (node.hasOwnProperty('children'))
            return node.children.map(node => mapChildren(node))
        return results.push(node)
    }
    
    for (let node of nodes) {
        mapChildren(node)
    }

    const permissions = results.filter(x => x.checked === 1).map(y => y.value)
    const modules = ['root'].concat(nodes[0].children.map(y => y.value))

    return {
        ...state,
        profilepermissions: nodes,
        checkeds: permissions,
        expandeds: modules,
        permissoesUI: permissoesUI
    }
}