import React from "react";
import { useStyles } from "../../hooks";
import clsx from "clsx";

import styles from "./jss/CustomInput";

import {
  cnpjMask,
  numberMask,
  phoneMask,
  postalCodeMask,
  plateMask,
} from "../../utils/masks";

const FunctionComponent = (props) => {
  const {
    id,
    input = "",
    setInput = () => "",
    placeholder = "",
    type = "text",
    mask = false,
    valid = true,
    ref = null,
    focused = false,
    showOptions = false,
    disabled = false,
    handleClick = () => {},
  } = props;

  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });
  const inputStyle = clsx({
    [classes.input]: true,
    [classes.hover]: !focused && !disabled,
    [classes.focusedInput]: focused,
    [classes.validInput]: focused && valid,
    [classes.errorInput]: focused && !valid,
    [classes.showOptionsInput]: focused && showOptions,
    [classes.disabled]: disabled,
  });

  const handleChange = (evt) => {
    if (mask) {
      if (mask === "cnpj") {
        setInput(cnpjMask(evt.target.value));
      }
      if (mask === "number") {
        setInput(numberMask(evt.target.value));
      }
      if (mask === "phone") {
        setInput(phoneMask(evt.target.value));
      }
      if (mask === "postalCode") {
        setInput(postalCodeMask(evt.target.value));
      }
      if (mask === "plateMask") {
        setInput(plateMask(evt.target.value));
      } else {
        setInput(evt.target.value);
      }
    }
  };

  return (
    <div className={rootStyle}>
      <input
        type={type}
        ref={ref}
        className={inputStyle}
        id={id}
        name={id}
        value={input}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onClick={handleClick}
      />
    </div>
  );
};

export const CustomInput = React.memo(FunctionComponent);

export default CustomInput;
