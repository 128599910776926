import React from 'react'
import { withRouter } from 'react-router-dom'

import { useStyles } from '..'
import styles from './jss/indicators'

import Tooltip from './Tooltip'

import { FaWifi, FaExclamationTriangle, FaBandAid, FaClock, FaTachometerAlt, FaUser } from 'react-icons/fa'
import { chunker } from '../../helpers'




const beltImage = require('../../assets/images/belt.png')
const hazardImage = require('../../assets/images/hazard.png')
const tempImage = require('../../assets/images/temperature.png')
const engineImage = require('../../assets/images/engine.png')


const Indicators = withRouter(({ history, id_montadora, telemetria, rssi, falhas, temperatura, motorista, cinto, evento, pisca, acelerador, data, rotacao, setDtc }) => {
    const classes = useStyles(styles)

    const faultClick = () => {
        if (!falhas.status || !falhas.id_dtc) return
        setDtc(falhas.id_dtc, id_montadora)
    }

    const eventClick = () => {
        if (!evento.status) return
        history.push('/monitoramento/relatorios/REL0002')
    }

    const items = [
        { isTelemetry: false, Component: <Tooltip Icon={FaWifi} {...rssi} key='1' /> },
        { isTelemetry: true, Component: <Tooltip image={engineImage}{...falhas} key='2' onClick={faultClick} pointer={falhas.status && Boolean(falhas.id_dtc)} /> },
        { isTelemetry: true, Component: <Tooltip image={tempImage} {...temperatura} key='3' /> },
        { isTelemetry: true, Component: <Tooltip image={beltImage} {...cinto} key='4' isLast /> },
        { isTelemetry: false, Component: <Tooltip Icon={FaUser}{...motorista} key='5' isLast /> },
        { isTelemetry: false, Component: <Tooltip Icon={FaExclamationTriangle} {...evento} key='6' onClick={eventClick} pointer={evento.status} /> },
        { isTelemetry: true, Component: <Tooltip image={hazardImage}{...pisca} key='7' /> },
        { isTelemetry: true, Component: <Tooltip Icon={FaTachometerAlt} {...rotacao} key='8' /> },
        { isTelemetry: true, Component: <Tooltip Icon={FaBandAid} {...acelerador} key='9' isLast /> },
        { isTelemetry: false, Component: <Tooltip Icon={FaClock} {...data} key='10' isLast /> }
    ].filter(x => !x.isTelemetry || telemetria)

    const renderItems = () => {
        const rows = chunker(items, 5)
        const result = []
        for (let i = 0; i < rows.length; i++) {
            result.push(
                <div className={classes.row} key={i}>
                    {rows[i].map(x => x.Component)}
                </div>
            )
        }
        return result
    }

    return (
        <div className={classes.root}>
            {renderItems()}
        </div>
    )
})

export default React.memo(Indicators)