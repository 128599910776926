import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { selectCompany } from '../../store/actions/Auth'

import { useStyles } from '..'
import styles from './styles'
import clsx from 'clsx'

import { FaChevronDown, FaCheck } from 'react-icons/fa'

import defaultImage from '../../assets/images/default.jpg'
import ClickAwayListener from '../ClickAwayListener/ClickAwayListener'


export const Companies = ({ companies, selected }) => {
    const classes = useStyles(styles)
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    const getImage = () => {
        if (selected.length === 1) {
            const company = selected[0]
            if (!company.imagem) return (
                <img src={defaultImage} className={classes.image} alt={`${company.nome_fantasia} logo`} />
            )
            return (
                <img src={company.imagem} className={classes.image} alt={`${company.nome_fantasia} logo`} />
            )
        }
        return (
            <div className={classes.pseudoImage}>
                {selected.length}
            </div>
        )
    }

    const onClickCompany = id => {
        dispatch(selectCompany(id))
    }

    const renderCompanies = () => {
        return companies.map(company => {
            const isActive = selected.map(x => x.id).includes(company.id)
            return (
                <div className={classes.item} key={company.id} onClick={() => onClickCompany(company.id)}>
                    <div className={classes.name}>
                        {company.nome_fantasia}
                    </div>
                    <div className={classes.grow} />
                    {isActive && <FaCheck className={classes.check} />}
                </div>
            )
        })
    }

    const paperStyle = clsx({
        [classes.paper]: true,
        [classes.expanded]: open
    })

    return (
        <div className={classes.wrapper}>
            <div className={classes.root} onClick={() => setOpen(!open)}>
                <div className={classes.avatar}>
                    {getImage()}
                </div>
                <FaChevronDown className={classes.chevron} />
            </div>

            <ClickAwayListener onClickAway={() => open ? setOpen(false) : null}>
                <div className={paperStyle}>
                    {renderCompanies()}
                </div>
            </ClickAwayListener>
        </div>
    )
}

export default Companies