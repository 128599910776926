import { SnackBarTypes as types } from '../types'

const initialState = {
    snackbar: {
        open: false,
        message: '',
        duration: 4000,
        variant: 'success'
    }
}

export const SnackBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SNACKBAR:
            return {
                ...state,
                snackbar: action.data
            }

        default:
            return state
    }
}