export const styles = (theme) => ({
  input: {},
  root: {
    position: "relative",
    width: "100%",
    userSelect: "none",
  },
  customTitleContainer: {
    minHeight: "1rem",
    marginBottom: 4,
    marginLeft: 3,
    fontSize: "0.75em",
  },
});

export default styles;
