import React from "react";
import { useStyles } from "../../hooks";
import clsx from "clsx";
import { BsExclamation } from "react-icons/bs";

import styles from "./jss/CustomTitle";

const FunctionComponent = (props) => {
  const { title = "", message = null, messageType = null } = props;
  const gotMessage =
    Boolean(message) && (messageType === "warning" || messageType === "error");

  const classes = useStyles(styles, props);

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.font]: true,
  });
  const iconStyle = clsx({
    [classes.icon]: true,
    [classes.warningIcon]: messageType === "warning",
    [classes.errorIcon]: messageType === "error",
    [classes.none]: !gotMessage,
  });
  const messageStyle = clsx({
    [classes.text]: true,
    [classes.warningMessage]: messageType === "warning",
    [classes.errorMessage]: messageType === "error",
    [classes.none]: !gotMessage,
  });

  return (
    <div className={rootStyle}>
      <div className={classes.container}>
        <span className={classes.text}>
          {title && gotMessage ? `${title}.` : title}
        </span>
        <BsExclamation className={iconStyle} />
        <span className={messageStyle}>{message}</span>
      </div>
    </div>
  );
};

export const CustomTitle = React.memo(FunctionComponent);

export default CustomTitle;
