/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import { uniqueId } from "../../helpers";
import { CustomTitle, CustomInput, ClickAwayListener, CustomOptions } from "..";

import styles from "./jss/CustomSimpleTextInput";

const FunctionComponent = (props) => {
  const {
    title = "",
    value = "",
    setValue = () => "",
    items = null,
    setItems = null,
    type = "text",
    min = 0,
    max = Number.POSITIVE_INFINITY,
    loading = false,
    customPlaceholder = null,
  } = props;

  let placeholder;

  if (customPlaceholder) placeholder = customPlaceholder;
  else if (min !== 0 && max !== Number.POSITIVE_INFINITY)
    placeholder = intl
      .get(`core.CustomSimpleTextInput.placeholderMinMax`)
      .replace("{min}", min)
      .replace("{max}", max);
  else if (min !== 0 && max === Number.POSITIVE_INFINITY)
    placeholder = intl
      .get(`core.CustomSimpleTextInput.placeholderMin`)
      .replace("{min}", min);
  else if (min === 0 && max !== Number.POSITIVE_INFINITY)
    placeholder = intl
      .get(`core.CustomSimpleTextInput.placeholderMax`)
      .replace("{max}", max);
  else placeholder = intl.get(`core.CustomSimpleTextInput.placeholder`);

  const idRef = useRef(uniqueId("CustomSimpleTextInput-"));
  const [showOptions, setShowOptions] = useState(() => false);
  const [input, setInput] = useState(() => "");
  const [error, setError] = useState(() => "");
  const classes = useStyles(styles, props);

  const validate = (input) => {
    setInput(input);

    if (input.length < min) {
      setError(
        intl.get(`core.CustomSimpleTextInput.minError`).replace("{min}", min)
      );
      setValue(null);
      return;
    }

    if (input.length > max) {
      setError(
        intl.get(`core.CustomSimpleTextInput.maxError`).replace("{max}", max)
      );
      setValue(null);
      return;
    }

    setError("");
    setValue(input);
  };

  const openOptions = () => setShowOptions(true);

  const toggleOptions = () => setShowOptions((showOptions) => !showOptions);

  const handleChange = (input) => {
    if (!loading) validate(input);
  };

  const handleClick = ({ label }) => {
    validate(label);
  };

  useEffect(() => {
    if (value !== null && value !== input) validate(value);
  }, [value]);

  return (
    <div className={classes.root}>
      {title && (
        <CustomTitle
          classes={{
            container: classes.customTitleContainer,
          }}
          title={title}
          message={error}
          messageType={error ? "error" : ""}
        />
      )}
      <CustomInput
        classes={{ input: classes.input }}
        id={idRef.current}
        input={input}
        setInput={handleChange}
        placeholder={placeholder}
        type={type}
        valid={!Boolean(error)}
        focused={showOptions}
        showOptions={showOptions && items && setItems}
        disabled={value && value.length === min && value.length === max}
        handleClick={openOptions}
        mask
      />
      {showOptions && (
        <ClickAwayListener ignore={idRef.current} onClickAway={toggleOptions}>
          {items && setItems && (
            <CustomOptions
              items={items}
              handleClick={handleClick}
              error={Boolean(error)}
              loading={loading}
            />
          )}
        </ClickAwayListener>
      )}
    </div>
  );
};

export const CustomSimpleTextInput = React.memo(FunctionComponent);

export default CustomSimpleTextInput;
