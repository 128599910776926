export default theme => ({
    tooltip: {
        position: 'relative',
        padding: 2,
        width: 16,
        marginRight: 5,
        height: 16,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            boxShadow: theme.elevation[1],
        },
        '&:active': {
            backgroundColor: '#5a606b'
        }
    },

    pointer: {
        cursor: 'pointer'
    },

    tooltipIcon: {
        color: 'white',
        fontSize: 15,
        width: 18,
        height: 18,
    },

    description: {
        position: 'absolute',
        top: -25,
        whiteSpace: 'nowrap',
        padding: '2px 5px',
        fontSize: 11,
        fontWeight: 500,
        backgroundColor: '#444444',
        border: '1px solid black',
        borderRadius: '.2rem',
        color: 'white',
        opacity: 0,
        transition: 'opacity 100ms ease-out',
        pointerEvents: 'none'
    },

    isLast: {
        right: 0
    },

    showDescription: {
        transition: 'opacity 100ms ease-in',
        opacity: 1
    },

    image: {
        width: 18,
        height: 18,
    }

})