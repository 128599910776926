import React from "react";
import { useHistory } from "react-router";
import { useStyles } from "../../hooks";
import intl from "react-intl-universal";
import { CustomWrapper } from "..";

import styles from "./jss/CustomErrorPage";

const FunctionComponent = (props) => {
  const { status = 403 } = props;

  const history = useHistory();
  const classes = useStyles(styles, props);

  const redirect = () => {
    history.push(`/`);
  };

  return (
    <CustomWrapper classes={{ container: classes.container }} withPanel={false}>
      <div className={classes.font}>
        <div className={classes.status}>{status}</div>
        <div className={classes.statusText}>
          {intl.get(`core.CustomErrorPage.${status}`)}
        </div>
        <div className={classes.redirect} onClick={redirect}>
          {intl.get("core.CustomErrorPage.redirect")}
        </div>
      </div>
    </CustomWrapper>
  );
};

export const CustomErrorPage = React.memo(FunctionComponent);

export default CustomErrorPage;
