export const crumbs = [
    {
        path: '/',
        icon: 'home',
        crumbs: [
            { path: '/', label: 'Visão Geral' },
        ]
    },
    {
        path: '/dashboard',
        icon: 'dashboard',
        crumbs: [
            { path: '/dashboard', label: 'Dashboard' },
        ]
    },
    {
        path: '/monitoramento/rastreador',
        icon: 'globe',
        crumbs: [
            { path: '/monitoramento', label: 'Monitoramento' },
            { path: '/monitoramento/rastreador', label: 'Rastreador' }
        ]
    },
    {
        path: '/monitoramento/cercas',
        icon: 'globe',
        crumbs: [
            { path: '/monitoramento', label: 'Monitoramento' },
            { path: '/monitoramento/cercas', label: 'Cercas' }
        ]
    },
    {
        path: '/monitoramento/telemetria',
        icon: 'globe',
        crumbs: [
            { path: '/monitoramento', label: 'Monitoramento' },
            { path: '/monitoramento/telemetria', label: 'Telemetria' }
        ]
    },
    {
        path: '/monitoramento/relatorios',
        icon: 'globe',
        crumbs: [
            { path: '/monitoramento', label: 'Monitoramento' },
            { path: '/monitoramento/relatorios', label: 'Relatórios' }
        ]
    },
    {
        path: '/monitoramento/alertas',
        icon: 'globe',
        crumbs: [
            { path: '/monitoramento', label: 'Monitoramento' },
            { path: '/monitoramento/relatorios', label: 'Alertas' }
        ]
    },
    {
        path: '/manutencao/dtc',
        icon: 'tools',
        crumbs: [
            { path: '/manutencao', label: 'Manutenção' },
            { path: '/manutencao/dtc', label: 'Diagnóstico Remoto' }
        ]
    }
]