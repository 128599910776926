/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { GoogleMap, TrafficLayer, useLoadScript } from '@react-google-maps/api'
import Spinner from '../Spinner/Spinner'

import { useStyles } from '..'
import styles from './styles'

const { REACT_APP_GOOGLE_KEY: key } = process.env

const defaultStyle = { height: '100vh', width: '100%', borderRadius: '0.3rem' }
const libraries = ['geometry', 'drawing', 'places']

export const Map = ({ options: _options, children, containerStyle, routeBounds, onIdle, setZoom }) => {
    const style = containerStyle ? { ...defaultStyle, ...containerStyle } : defaultStyle
    const { center, zoom, ...optionsWithoutCenterZoom } = _options;
    const options = { ...optionsWithoutCenterZoom, streetViewControl: false }
    const _showTraffic = (options.showTraffic) ? options.showTraffic : false
    const [traffic, setTraffic] = useState(false)
    const [mapRef, setMapRef] = useState(null)

    const classes = useStyles(styles)

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: key,
        libraries
    })

    useEffect(() => {
        if (window.google && mapRef) fitBounds(routeBounds, mapRef)
    }, [mapRef, routeBounds])

    useEffect(() => {
        if(mapRef) mapRef.setZoom(_options.zoom);
    }, [_options])

    const loadHandler = map => {
        setMapRef(map)
    }

    const handleIdle = () => {
        if (mapRef) {
            onIdle({ zoom: mapRef.getZoom(), center: mapRef.getCenter() })
            setZoom(mapRef.getZoom())
        }
    }

    const renderMap = () => {
        return <GoogleMap
            options={options}
            mapContainerStyle={style}
            center={_options.center}
            zoom={_options.zoom}
            onLoad={loadHandler}
            id='drawing-map'
            streetViewControl={true}
            onIdle={() => handleIdle()}
        >
            {_showTraffic && <div
                className={classes.customButton}
                onClick={() => setTraffic(!traffic)}
                title='Mostrar trânsito agora'
            >Trânsito</div>}
            {traffic && <TrafficLayer autoUpdate />}
            {children}
        </GoogleMap>
    }

    if (loadError) {
        return <div>Desculpe, o mapa não pôde ser carregado agora</div>
    }

    return isLoaded ? renderMap() : <Spinner />
}

// Iterate data to size, center, and zoom map to contain all markers
const fitBounds = (routeBounds, mapRef) => {
    if (routeBounds) {
        const bounds = new window.google.maps.LatLngBounds()
        routeBounds.map(point => {
            return bounds.extend({ lat: point.lat, lng: point.lng })
        })
        mapRef.fitBounds(bounds)
    }
}

Map.defaultProps = {
    onIdle: () => {},
    setZoom: () => {}
}

export default Map