export default theme =>({
    root:{
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        paddingLeft: '20%',
        cursor: 'pointer',
        color: theme.drawer.color,
        '&:hover':{
            color: theme.drawer.colorHover
        }
    },

    active:{
        color: theme.drawer.colorHover
    },

    label:{
        fontSize: 14,
        marginRight: 20
    },

    chevron: {
        marginTop: 3,
        fontSize: 12,
        transition: 'transform 180ms ease'
    },

    opened:{
        transform: 'rotate(-90deg)'
    },

    collapse:{
        paddingLeft: '10%',
        backgroundColor: 'rgba(0,0,0,.2)'
    }
})