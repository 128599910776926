import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import {theme} from "./styles/theme/theme";
import store from "./store/store";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import Content from "./Content";


ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Content />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
