import { theme } from "../../../styles";

export const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "space-around",
    overflow: "auto",
    userSelect: "none",
  },
  font: {
    fontFamily: theme.typography.standard.fontFamily,
    fontSize: theme.typography.standard.fontSize,
    fontWeight: theme.typography.standard.fontWeightRegular,
    letterSpacing: theme.typography.standard.letterSpacing,
    color: theme.palette.text.secondary,
  },
  exportIconsContainer: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    margin: theme.dimensions.customMargin.small,
  },
  toolbarContent: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  toolbarContainer: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

export const headerStyle = {
  height: theme.dimensions.customHeight.medium,
  borderColor: "rgba(224, 224, 224, 1)",
  borderTopStyle: "solid",
  borderWidth: theme.dimensions.customBorder.small,
  fontSize: "1.05em",
  fontWeight: theme.typography.standard.fontWeightBold,
  textAlign: "center",
  whiteSpace: "nowrap",
};

export const headerStyleWithSorting = {
  paddingLeft: 42,
};

export const cellStyle = {
  fontSize: "0.95em",
  textAlign: "center",
  whiteSpace: "nowrap",
};

export default styles;
