export default theme => ({
    editPersonal: {
        height: '95vh',
        maxHeight: '95vh',
        minWidth: '30%',
        [theme.breakpoints.down('sm')]: {
            minWidth: '50%'
        },
        overflowY: 'hidden'
    },

    dialog: {
        zIndex: '99999 !important'
    },

    hidden: {
        position: 'absolute',
        opacity: 0,
        top: 0,
        width: 0,
        height: 0
    },

    inputUpload: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },

    labelImage: {
        background: '#757575',
        position: 'absolute',
        marginLeft: '-18px',
        marginTop: '42px',
        marginBottom: 0,
        display: 'inline-block',
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        border: '1px solid transparent',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.12)',
        cursor: 'pointer',
        fontWeight: 'normal',
        transition: 'all 0.2s ease -in -out',
        '&:hover': {
            background: '#b1b1b1',
            borderColor: '#d6d6d6'
        },
        '&:after': {
            color: '#757575',
            position: 'absolute',
            top: '10px',
            left: 0,
            right: 0,
            textAlign: 'center',
            margin: 'auto'
        }
    },

    icon: {
        color: 'white',
        position: 'absolute',
        top: '6px',
        left: '6px',
        right: '6px',
        fontSize: '1.2rem'
    },

    profileImage: {
        width: 80,
        height: 80,
        overflow: 'hidden',
        borderRadius: '50%',
        cursor: 'pointer'
    },

    formContainer: {
        padding: 14,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #d3d3d3',
        borderRadius: '.3rem',
        position: 'relative',
        outline: 'none'
    },

    labelEdit: {
        position: 'absolute',
        padding: '0 5px',
        zIndex: 1,
        fontSize: 13,
        color: '#495057',
        left: 10,
        top: '-7%',
        backgroundColor: 'white'
    },

    labelEditPass: {
        position: 'absolute',
        padding: '0 5px',
        zIndex: 1,
        fontSize: 13,
        color: '#495057',
        left: 10,
        top: '-3%',
        backgroundColor: 'white'
    },

    checkContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    checkLabel: {
        fontSize: 15,
        color: '#495057',
        cursor: 'pointer'
    },

    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },

    button: {
        height: 35,
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        outline: 'none',
        borderRadius: '.3rem',
        color: theme.palette.primary.main,
        fontSize: 16,
        textAlign: 'center',
        fontWeight: 600,
        padding: 10,
        cursor: 'pointer',
        '&:active': {
            border: '1px solid #0A6EBD'
        }
    },

    disabled: {
        opacity: .6,
        cursor: 'not-allowed'
    },

    close: {
        padding: 10,
        cursor: 'pointer',
        color: '#444444',
        position: 'absolute',
        right: 0,
        top: 0
    },

    info: {
        padding: 5,
        margin: 0,
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 600,
        wordBreak: 'break-word',
        color: '#495057',
        '& > span': {
            fontWeight: 'normal',
            marginLeft: 5
        }
    },

    informations: {
        position: 'relative'
    },

    label: {
        position: 'absolute',
        padding: '0 5px',
        zIndex: 1,
        fontSize: 13,
        color: '#495057',
        left: 10,
        top: '-6%',
        backgroundColor: 'white'
    },

    informationsContainer: {
        border: '1px solid #d3d3d3',
        borderRadius: '.3rem',
        padding: '10px 5px',
        marginTop: 20,
        position: 'relative',
        outline: 'none',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',

    },

    forcarTrocar: {
        border: '1px solid #0412b8',
        borderRadius: '.3rem',
        backgroundColor: '#9cadff',
        '& > p': {
            fontSize: 14,
            color: 'white',
            padding: 5,
            margin: 0
        }
    },

    msgErro: {
        '& > p': {
            fontSize: 14,
            color: 'red',
            padding: 5,
            margin: 0
        }
    },

    containerActions: {
        justifyContent: 'space-between',
        alignItems: 'baseline',
        display: 'flex'
    }
})