import React from 'react'

import { useStyles, Dialog } from '..'
import styles from './jss/mobileDetails'
import { FaWifi, FaDrawPolygon, FaTachometerAlt, FaBandAid, FaGasPump, FaCloudscale, FaCarSide, FaClock } from 'react-icons/fa'

const beltImage = require('../../assets/images/belt.png')
const batteryImage = require('../../assets/images/battery.png')
const hazardImage = require('../../assets/images/hazard.png')
const tempImage = require('../../assets/images/temperature.png')
const engineImage = require('../../assets/images/engine.png')

export const MobileDetails = ({ status, ...dialogProps }) => {
    const classes = useStyles(styles)
    const { rssi, falhas, temperatura, cinto, tensao, cerca, pisca, rotacao, acelerador, comb, odometer, speed, data } = status
    return (
        <Dialog
            {...dialogProps}
        >
            <div className={classes.root}>
                <div className={classes.item}>
                    <FaWifi className={classes.icon} />
                    <div className={classes.text}>
                        {rssi.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <img src={engineImage} alt='engine' className={classes.image} />
                    <div className={classes.text}>
                        {falhas.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <img src={tempImage} alt='engine' className={classes.image} />
                    <div className={classes.text}>
                        {temperatura.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <img src={beltImage} alt='engine' className={classes.image} />
                    <div className={classes.text}>
                        {cinto.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <img src={batteryImage} alt='engine' className={classes.image} />
                    <div className={classes.text}>
                        {tensao.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <FaDrawPolygon className={classes.icon} />
                    <div className={classes.text}>
                        {cerca.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <img src={hazardImage} alt='engine' className={classes.image} />
                    <div className={classes.text}>
                        {pisca.descricao}
                    </div>
                </div>
                <div className={classes.item}>
                    <FaTachometerAlt className={classes.icon} />
                    <div className={classes.text}>
                        {rotacao.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <FaBandAid className={classes.icon} />
                    <div className={classes.text}>
                        {acelerador.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <FaGasPump className={classes.icon} />
                    <div className={classes.text}>
                        {comb.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <FaCloudscale className={classes.icon} />
                    <div className={classes.text}>
                        {odometer.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <FaCarSide className={classes.icon} />
                    <div className={classes.text}>
                        {speed.descricao}
                    </div>
                </div>

                <div className={classes.item}>
                    <FaClock className={classes.icon} />
                    <div className={classes.text}>
                        {data.descricao}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}